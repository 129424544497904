import { useLocation, navigate } from "@reach/router";
import React from "react";
import { MdClose } from "react-icons/md";
import { useSelector, shallowEqual } from "react-redux";

const LocalModals = ({
  setHandleClose,
  handleShowLocale,
  handleOpenLocationBar,
  shippingTypeState,
  range,
  url
}) => {
  const location = useLocation();

  const SHOW_LOCAL_SELLERS_OR_PRODUCTS_TEXT = location.href.includes("stores")
    ? "Show Local Sellers"
    : "Show Local Products";

  const distanceState = useSelector(
    state => state.categoryReducer.distance,
    shallowEqual
  );

  const userLocState = useSelector(
    state => state.userLocationReducer,
    shallowEqual
  );

  switch (shippingTypeState) {
    case 6:
      return (
        <>
          <i
            className="material-icons location-box-close-icon no-select"
            onClick={setHandleClose}
          >
            <MdClose />
          </i>
          Unfortunately all pickup locations for this seller are more than{" "}
          {range}km away and they do not ship to your auto detected location at{" "}
          {Object.keys(userLocState).includes("city") &&
          Object.keys(userLocState).includes("state")
            ? `${userLocState.city}, ${userLocState.state}`
            : ""}
          . Click a button below to View This Product, Show Only Local Sellers,
          or to Change your Location or Radius.
          <div className="buttons-modal">
            <button
              className="sl-btn sl-btn-confirm"
              onClick={() => navigate(url)}
            >
              View this product
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => handleShowLocale(e)}
            >
              {SHOW_LOCAL_SELLERS_OR_PRODUCTS_TEXT}
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => {
                e.preventDefault();
                setHandleClose();
                handleOpenLocationBar();
              }}
            >
              Change Location or Radius
            </button>
          </div>
        </>
      );

    case 5:
      return (
        <>
          <i
            className="material-icons location-box-close-icon no-select"
            onClick={setHandleClose}
          >
            <MdClose />
          </i>
          Unfortunately all pickup locations for this seller are more then{" "}
          {range}km away and they do not ship to your auto detected location at{" "}
          {Object.keys(userLocState).includes("city") &&
          Object.keys(userLocState).includes("state")
            ? `${userLocState.city}, ${userLocState.state}`
            : ""}
          . Click a button below to View This Product, Show Only Local Sellers,
          or to Change your Location or Radius.
          <div className="buttons-modal">
            <button
              className="sl-btn sl-btn-confirm"
              onClick={() => navigate(url)}
            >
              View this product
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => handleShowLocale(e)}
            >
              {SHOW_LOCAL_SELLERS_OR_PRODUCTS_TEXT}
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => {
                e.preventDefault();
                setHandleClose();
                handleOpenLocationBar();
              }}
            >
              Change Location or Radius
            </button>
          </div>
        </>
      );
    case 4:
      return (
        <>
          <i
            className="material-icons location-box-close-icon no-select"
            onClick={setHandleClose}
          >
            <MdClose />
          </i>
          Unfortunately all pickup locations for this seller are more then{" "}
          {range}km away and they do not ship to your auto detected location at{" "}
          {Object.keys(userLocState).includes("city") &&
          Object.keys(userLocState).includes("state")
            ? `${userLocState.city}, ${userLocState.state}`
            : ""}
          . Click a button below to View This Product, Show Only Local Sellers,
          or to Change your Location or Radius.
          <div className="buttons-modal">
            <button
              className="sl-btn sl-btn-confirm"
              onClick={() => navigate(url)}
            >
              View this product
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => handleShowLocale(e)}
            >
              {SHOW_LOCAL_SELLERS_OR_PRODUCTS_TEXT}
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => {
                e.preventDefault();
                setHandleClose();
                handleOpenLocationBar();
              }}
            >
              Change Location or Radius
            </button>
          </div>
        </>
      );
    case 3:
      return (
        <>
          <i
            className="material-icons location-box-close-icon no-select"
            onClick={setHandleClose}
          >
            <MdClose />
          </i>
          Unfortunately this seller does not ship to your auto detected location
          at{" "}
          {Object.keys(userLocState).includes("city") &&
          Object.keys(userLocState).includes("state")
            ? `${userLocState.city}, ${userLocState.state}`
            : ""}
          . Click a button below to View This Product, Show Only Local Sellers,
          or to Change your Location or Radius.
          <div className="buttons-modal">
            <button
              className="sl-btn sl-btn-confirm"
              onClick={() => navigate(url)}
            >
              View this product
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => handleShowLocale(e)}
            >
              {SHOW_LOCAL_SELLERS_OR_PRODUCTS_TEXT}
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => {
                e.preventDefault();
                setHandleClose();
                handleOpenLocationBar();
              }}
            >
              Change Location or Radius
            </button>
          </div>
        </>
      );
      break;
    case 2:
      return (
        <>
          <i
            className="material-icons location-box-close-icon no-select"
            onClick={setHandleClose}
          >
            <MdClose />
          </i>
          Unfortunately this seller does not ship to your auto detected location
          at{" "}
          {Object.keys(userLocState).includes("city") &&
          Object.keys(userLocState).includes("state")
            ? `${userLocState.city}, ${userLocState.state}`
            : ""}
          . Click a button below to View This Product, Show Only Local Sellers,
          or to Change your Location or Radius.
          <div className="buttons-modal">
            <button
              className="sl-btn sl-btn-confirm"
              onClick={() => navigate(url)}
            >
              View this product
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => handleShowLocale(e)}
            >
              {SHOW_LOCAL_SELLERS_OR_PRODUCTS_TEXT}
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => {
                e.preventDefault();
                setHandleClose();
                handleOpenLocationBar();
              }}
            >
              Change Location or Radius
            </button>
          </div>
        </>
      );
      break;
    default:
      return (
        <>
          <i
            className="material-icons location-box-close-icon no-select"
            onClick={setHandleClose}
          >
            <MdClose />
          </i>
          Unfortunately this seller does not ship to your auto detected location
          at{" "}
          {Object.keys(userLocState).includes("city") &&
          Object.keys(userLocState).includes("state")
            ? `${userLocState.city}, ${userLocState.state}`
            : ""}
          . Click a button below to View This Product, Show Only Local Sellers,
          or to Change your Location or Radius.
          <div className="buttons-modal">
            <button
              className="sl-btn sl-btn-confirm"
              onClick={() => navigate(url)}
            >
              View this product
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => handleShowLocale(e)}
            >
              {SHOW_LOCAL_SELLERS_OR_PRODUCTS_TEXT}
            </button>
            <button
              className="sl-btn sl-btn-confirm"
              onClick={e => {
                e.preventDefault();
                setHandleClose();
                handleOpenLocationBar();
              }}
            >
              Change Location or Radius
            </button>
          </div>
        </>
      );
      break;
  }
};

export default LocalModals;
