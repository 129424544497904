import React, { useEffect, useRef, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import Img from "gatsby-image";
import { MdClose } from "react-icons/md";
const Banner = ({ setScrollPos }) => {
  const countdownDisplayRef = useRef(null);
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);

  const data = useStaticQuery(graphql`
    query {
      auctionTop: file(relativePath: { eq: "auctionLogo22.png" }) {
        childImageSharp {
          fluid(maxWidth: 950) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      auctionSupportOf: file(relativePath: { eq: "auctionSupportOf.png" }) {
        childImageSharp {
          fluid(maxWidth: 950) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      auctionPrizes: file(relativePath: { eq: "auctionPrizes.png" }) {
        childImageSharp {
          fluid(maxWidth: 950) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      auctionProjects: file(relativePath: { eq: "auctionProjects.png" }) {
        childImageSharp {
          fluid(maxWidth: 950) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopup = () => {
    setPopupOpen(current => !current);
  };

  // if (document.getElementById("countdownDisplay")) {
  //   var startDate = new Date("Nov 11, 2023 12:00:00").getTime();

  //   var interval = setInterval(function () {
  //     var currentTime = new Date().getTime();

  //     var timeRemaining = startDate - currentTime;

  //     var days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  //     var hours = Math.floor(
  //       (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     var minutes = Math.floor(
  //       (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
  //     );
  //     var seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  //     if (document.getElementById("countdownDisplay")) {
  //       document.getElementById("countdownDisplay").innerHTML =
  //         days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
  //     }

  //     if (timeRemaining < 0) {
  //       clearInterval(interval);
  //       document.getElementById("auctionCountDown").classList.add("hidden");
  //     }
  //   }, 1000);
  // }

  function calculateTimeRemaining() {
    const startDate = new Date("Nov 11, 2023 12:00:00").getTime();
    const currentTime = new Date().getTime();
    const timeRemaining = startDate - currentTime;
    return timeRemaining;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("remaining123a");
      const remaining = calculateTimeRemaining();
      setTimeRemaining(remaining);

      const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remaining % (1000 * 60)) / 1000);

      if (countdownDisplayRef.current) {
        countdownDisplayRef.current.innerHTML = `${days}d ${hours}h ${minutes}m ${seconds}s `;
      }
      console.log("remaining123b");
      if (remaining < 0) {
        clearInterval(interval);
        const auctionCountDown = document.getElementById("auctionCountDown");
        if (auctionCountDown) {
          auctionCountDown.classList.add("hidden");
        }
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  console.log("remaining123", timeRemaining);
  return (
    <div>
      <div className="auctionTop">
        <div id="auctionBanner">
          <img src="https://ik.imagekit.io/ofb/RT/auctionLogo2023_SJCoi6w2e.jpg" />
        </div>

        <div className="supportedProjects">
          <div className="projectsLeft">
            <p>
              Bidding <strong>starts November 11</strong> and{" "}
              <strong>ends November 25</strong> at 9PM
            </p>

            <h2>Local Projects Supported</h2>

            <ul>
              <li>Royal Victoria Regional Health Centre</li>
              <li>Georgian College</li>
              <li>Hospice Simcoe</li>
              <li>Southshore Community Centre</li>
              <li>Thousands of trees planted around Barrie</li>
            </ul>

            {/* <p>
              <a href="http://www.barrierotaryauction.com">
                BarrieRotaryAuction.com
              </a>
            </p> */}
          </div>
          <div className="projectsRight">
            <Img fluid={data.auctionProjects.childImageSharp.fluid} />
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="auctionBlock1Head auctionBlockHead">
          <h2>Auction Block 1</h2>
          <p>Ends Saturday, November 25, 2023 at 8:15pm</p>
        </div>

        <div className="auctionBlock2Head auctionBlockHead">
          <h2>Auction Block 2</h2>
          <p>Ends Saturday, November 25, 2023 at 8:30pm</p>
        </div>

        <div className="auctionBlock3Head auctionBlockHead">
          <h2>Auction Block 3</h2>
          <p>Ends Saturday, November 25, 2023 at 8:45pm</p>
        </div>

        <div className="auctionBlock4Head auctionBlockHead">
          <h2>Auction Block 4</h2>
          <p>Ends Saturday, November 25, 2023 at 9:00pm</p>
        </div>
      </div>

      <div className="clearfix"></div>

      {/* <div id="auctionCountDown">
        <p className="auctionCountdownTitle">Auction Begins in:</p>
        <p ref={countdownDisplayRef} id="countdownDisplay"></p>
      </div> */}

      <div className="auctionButtons">
        <div className="auctionBtns1">
          {/* <div className="donateBtn">
            <a className="auctionBtn" href="https://events.barrierotary.com/home/auction" target="_blank">
              Donate Here
            </a>
          </div> */}
          <div>
            <div className="auctionBtn" onClick={() => handlePopup()}>
              View Auction Instructions
            </div>
          </div>
        </div>

        {/* <h2 className="earlyBirdTitle text-center">Early Bird Preview Auction Items<br/>
        (New Items Added Daily)</h2> */}

        <div id="auctionBtns2" className="auctionBtns2">
          <Link
            onClick={() => {
              setScrollPos(window.scrollY);
            }}
            to="/barrie-rotary-auction/"
          >
            <div className="auctionBtn auctionBlockAllBtn">View All</div>
          </Link>
          <Link
            onClick={() => {
              setScrollPos(window.scrollY);
            }}
            to="/barrie-rotary-auction/block-1/"
          >
            <div className="auctionBtn auctionBlock1Btn">Block 1</div>
          </Link>
          <Link
            onClick={() => {
              setScrollPos(window.scrollY);
            }}
            to="/barrie-rotary-auction/block-2/"
          >
            <div className="auctionBtn auctionBlock2Btn">Block 2</div>
          </Link>
          <Link
            onClick={() => {
              setScrollPos(window.scrollY);
            }}
            to="/barrie-rotary-auction/block-3/"
          >
            <div className="auctionBtn auctionBlock3Btn">Block 3</div>
          </Link>
          <Link
            onClick={() => {
              setScrollPos(window.scrollY);
            }}
            to="/barrie-rotary-auction/block-4/"
          >
            <div className="auctionBtn auctionBlock4Btn">Block 4</div>
          </Link>
        </div>
      </div>

      <div id="instructionsPopupBack" className={popupOpen ? "" : "hidden"}>
        <div id="instructionsPopupBody">
          <div className="auctionInstClose" onClick={() => handlePopup()}>
            <i class="material-icons">
              <MdClose />
            </i>
          </div>
          <div id="instructionsPopupContent">
            <p>
              All monies raised will go to help our local community and
              international projects. Major projects are RVH - Hearts & Minds
              Youth and Mental Health and Hospice Simcoe
            </p>

            <p>
              Check out our website www.barrierotary.com to see the many
              accomplishments of our club. Follow us on Facebook and Twitter
            </p>

            <p>
              Bidding starts <strong>Saturday November 11, 2023</strong> at
              12:00 pm
            </p>

            <ul>
              <li>
                Make sure to Create an Account and Login in before the auction
                starts.
              </li>
              <li>To place a bid, you must be logged in.</li>
            </ul>

            <p>
              All bidding closes on <strong>Saturday November 25, 2023</strong>{" "}
              at the following times:
            </p>

            <ul>
              <li>Items in Block 1 will close at 8:15 pm sharp!</li>
              <li>Items in Block 2 will close at 8:30 pm sharp!</li>
              <li>Items in Block 3 will close at 8:45 pm sharp!</li>
              <li>Items in Block 4 will close at 9:00 pm sharp!</li>
            </ul>

            <p>Bid with Desktop and Mobile Devices.</p>

            <p>
              <strong>Item Pick Up:</strong>
            </p>

            <p>
              Your bid is a contract between you and the Rotary Club of Barrie.
              If you have the highest bid you will enter into a legally binding
              purchase contract. Once payment has been verified, the winning
              bidder will be emailed a voucher to claim their item within a week
              of the end of the auction. Unless otherwise stated, items are to
              be picked up at the donor’s place of business.
            </p>

            <p>
              Any additional questions please contact{" "}
              <a href="mailto:barrierotaryauction@gmail.com">
                barrierotaryauction@gmail.com
              </a>{" "}
              and someone will be happy to answer them for you.
            </p>

            <div className="auctionBtn" onClick={() => handlePopup()}>
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
