import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

const Banner = ({categoryItems,data}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopup = () => {
    setPopupOpen(current => !current);
  };

  const handleChooseItem = (index) => {
    let currentCategory = {
      items: categoryItems,
      url: data.url
    };
    /*  categoryItems.map(c => {
      currentCategory.items.push(c.url);
    }); */
    localStorage.setItem("currentCategory", JSON.stringify(currentCategory));
    localStorage.setItem("currentIndex", index);
    // dispatch(setCurrentCategory(
    //   {
    //     currentIndex: index,
    //     categoryItems: {...categoryItems},
    //     supplier: {...supplier}
    //   }
    // ));
  };

  return (
    <div>
      <div id="funRunGrid">

        <div id="funRunBanner">

          <div id="funRunBannerRTLogo"></div>
          <div id="funRunBannerLogo">
            <img src="https://ik.imagekit.io/ofb/RT/funRunLogo2024_21JG4181z.png?updatedAt=1712260396079" />
          </div>
          <div id="funRunBannerRegister">
            <span>Register<br/>Now!</span>
          </div>
          <div id="funRunBannerDate">
            <span>Wednesday, May 29th at The Southshore Centre in Barrie</span>
          </div>

        </div>

        <div id="funRunImg1">
          <img src="https://ik.imagekit.io/ofb/RT/FunRun1_xp5jxkbcfW.jpg?updatedAt=1679681594101" />
        </div>

        <div id="funRunText1">
          <h2>It's Time To Gear Up For The 37th Annual Barrie Fun Run!</h2>

          <p>Have you heard that this year's fun run will be our 37th starting at the Southshore Community Centre on Lakeshore drive in Barrie? That means that for 37 years we've been helping raise money for exciting projects in our community like supporting our local hospital as it grows alongside Barrie, building thrilling new attractions like the fountain on the lake shore and keeping it green investing in tree planting and this year you can be a part of these projects yet again!</p>
 
          <p>This year's race will be held at the South Shore Community Center where we will run or walk along the beautiful and newly updated lakeshore of Barrie on May 29, 2024!</p>
 
          <div className="funRunBtn">
            <p>
              <a href="https://barrierotary.com/fun-run/#sponsor-fun-run" target="_blank">
                Learn How You Can Become an Event Sponsor
              </a>
            </p>
          </div>

          {/* <div className="funRunBtn">
            <p>
              <Link to="/">
                View the Route
              </Link>
            </p>
          </div> */}

        </div>

        <div id="funRunText2">

          <p className="text">Registration is now open and spaces fill up fast! Rally your friends, family, co-workers to join you. Register as an individual or as a team but register today because this year's event will not only be the best yet, but all proceeds go directly back into our community through initiatives of the Rotary Club of Barrie. </p>
          
          <h2>EVENT DETAILS & <br/>EARLY BIRD RATES</h2>
          <p className="priceInfo">250M Kids Race ONLY $10/person</p>
          <p className="priceInfo">5k chip timed race ONLY $40/person</p>
          <p className="priceInfo">5k walk along Barrie's waterfront ONLY $40/person</p>
          <p className="priceInfo">10k chip timed race ONLY $40/person</p>


        </div>

        <div id="funRunImg2">
          <img src="https://ik.imagekit.io/ofb/RT/funRun2_nGdHhpa61Z.jpg?updatedAt=1679681593301" />
        </div>

        <div id="funRunImg3">
          <img src="https://ik.imagekit.io/ofb/RT/FunRunPhoto696e4df6-f8e2-49f0-a766-7929ff19e035_kuQaNj1t4.jpeg?updatedAt=1680275544708" />
        </div>

        <div id="funRunText3">
          
          <h2>Want to see just <br/>HOW MUCH FUN It will be?</h2>

          <p>Check Out Our Video From Last Year's Race!</p>

          <div className="youtubeVid">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/EnWl1Dy7RlA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>

        </div>

        {/* <div id="funRunImg4">
          <img src="https://ik.imagekit.io/ofb/RT/funRun3_Svc11LVlc.jpg?updatedAt=1679682603138" />
        </div>

        <div id="funRunSponsors">

          <h3>Thank You to our Platinum/Headliner Sponsor</h3>

          <div id="platinumSponsor">
            <img src="https://placehold.co/600x200?text=Platinum+Sponsor+Logo" />
          </div>

          <h3>Thank You to our Diamond/Superstar Sponsors</h3>

          <div id="diamondSponsorGrid">
            <div>
              <img src="https://placehold.co/400x150?text=Diamond+Sponsor+Logo"/>
            </div>
            <div>
              <img src="https://placehold.co/400x150?text=Diamond+Sponsor+Logo"/>
            </div>
          </div>


          <h3>Thank You to our Gold/Idols Sponsors</h3>

          <div id="goldSponsorGrid">
            <div>
              <img src="https://placehold.co/200x150?text=Gold+Sponsor+Logo"/>
            </div>

            <div>
              <img src="https://placehold.co/200x150?text=Gold+Sponsor+Logo"/>
            </div>

            <div>
              <img src="https://placehold.co/200x150?text=Gold+Sponsor+Logo"/>
            </div>

            <div>
              <img src="https://placehold.co/200x150?text=Gold+Sponsor+Logo"/>
            </div>

            <div>
              <img src="https://placehold.co/200x150?text=Gold+Sponsor+Logo"/>
            </div>

            <div>
              <img src="https://placehold.co/200x150?text=Gold+Sponsor+Logo"/>
            </div>

            <div>
              <img src="https://placehold.co/200x150?text=Gold+Sponsor+Logo"/>
            </div>

            <div>
              <img src="https://placehold.co/200x150?text=Gold+Sponsor+Logo"/>
            </div>
          </div>

        </div> */}

        <div id="funRunCharity">
          <span>ALL PROCEEDS GO TO SUPPORT CHARITY PROJECTS OF THE ROTARY CLUB OF BARRIE</span>
        </div>

        <div id="funRunRegister">
          <h3>Click the Buttons Below to Register Now!</h3>

          <div id="funRunRegisterGrid">

            <div className="funRunBtn">
              <p>
                <Link to="/fun-run/5k-fun-run-race-aine3inco2" onClick={() => handleChooseItem(0)}>
                  5K Race
                </Link>
              </p>
            </div>

            <div className="funRunBtn" onClick={() => handleChooseItem(1)}>
              <p>
                <Link to="/fun-run/10k-fun-run-race-ainzs7kwf0">
                  10K Race
                </Link>
              </p>
            </div>

            <div className="funRunBtn">
              <p>
                <Link to="/fun-run/5k-fun-walk-ainxn3nx52" onClick={() => handleChooseItem(2)}>
                  5K Walk
                </Link>
              </p>
            </div>

            <div className="funRunBtn" onClick={() => handleChooseItem(3)}>
              <p>
                <Link to="/fun-run/kids-250m-fun-run-ainf4hnf83">
                  250M Kids
                </Link>
              </p>
            </div>

            <div className="funRunBtn">
              <p>
                <Link
                  to="/fun-run/corporate-team-aindxgukp2"
                  onClick={() => handleChooseItem(4)}
                >
                  Corporate Team
                </Link>
              </p>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default Banner;
