import React from "react";
import CartValidationErrors from "../AC-ProductPage/Components/CartValidationErrors/CartValidationErrors";
import { Link } from "gatsby";

import raffleLogo from "../../assets/img/raffleLogo.jpg";
import bellLogo from "../../assets/img/sponsors/bellLogo.png";
import theSarjeantLogo from "../../assets/img/sponsors/theSarjeantLogo.png";
import northernPlumbingLogo from "../../assets/img/sponsors/northernPlumbingLogo.png";
import jeffWaltersJewellers from "../../assets/img/sponsors/jeffWaltersJewellers.png";
import avettiLogo from "../../assets/img/sponsors/avettiLogo.png";
import sponsorLogo2 from "../../assets/img/sponsors/Rock95-logotagline.png";
import sponsorLogo3 from "../../assets/img/sponsors/KoolfFM-logotagline.png";

const Banner = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearCurrentCategory = () => {
    localStorage.removeItem("currentIndex");
    localStorage.removeItem("currentCategory");
  };

  return (
    <div className="text-center presidentsBash">
      <br />
      <br />

      <h1>
        President’s Bash 2021!
        <br />
        President Deb’s Country Jamboree – YeeHaw!
      </h1>

      <br />
      <br />

      <p>
        Unfortunately, the ability to buy items from the country-themed menu is
        now closed.
      </p>

      {/*<p>It’s been a Rotary year like no other!  Please join in celebrating President Deb’s virtual year with a virtual Country Jamboree!</p>

      <p>Dig out your favourite country attire to get in the spirit, fill your background with country décor and settle in for a fun evening of Rotary fellowship.</p>
      
      <p>Spouses and family are very welcome. </p>

      <p>And… check out this amazing country-themed menu from John O’Brien and his crew at Wildwood – you can build your own menu from lots of selection, add some apps and DEFINITELY, reserve your FREE Country Bumpkin Peach Tea, suitable for spiking or drinking as is – especially for toasting President Deb. </p>

      <br/><br/>

      <h1>Menu</h1>

      <br/><br/>

      <p className="boldunderline">All entrées served with</p>

      <p>Stuffed Baked Potato<br/>
      
      <span className="small">(sour cream, cheddar cheese & green onion)</span></p>

      <br/>

      <p>Summer Country Salad, Grilled Fresh Vegetables & Strawberry Rhubarb Cobbler</p>
      
      <br/><br/>

      <h2>Main Entrée Choices</h2>

      <p className="stylized">BBQ AAA Striploin Steak</p>

      <p>(aaa western Canadian grain fed aged 30 days)</p>
      
      <p>or</p>

      <p className="stylized">AAA Beef Brisket & Frazzled Onions</p>

      <p>(cooked low and slow for 50 hours)</p>

      <p><Link to="/presidents-bash/bbq-aaa-striploin-steak-ain3y5jzq2" onClick={clearCurrentCategory}>Steak</Link> or <Link to="/presidents-bash/aaa-beef-brisket-frazzled-onions-ainof14hq2" onClick={clearCurrentCategory}>Brisket</Link> Dinner - <span className="red">$32.00 pp</span></p>

      <br/><br/>

      <p className="stylized">BBQ Chicken Breast</p>

      <p className="small">boneless marinated & glazed with smoky bbq sauce</p>

      <p className="stylized">Southwest Burrito Bowl (vegetarian)</p>

      <p>roasted veg, savoury beans, seasoned rice, with a cilantro-lime cream dressing & fresh avocado </p>

      <p><Link to="/presidents-bash/bbq-chicken-breast-ain9wodwp2" onClick={clearCurrentCategory}>BBQ Chicken</Link> or <Link to="/presidents-bash/southwest-burrito-bowl-vegetarian-ainq3a7vg2" onClick={clearCurrentCategory}>Burrito Bowl</Link> - <span class="red">$25 pp</span></p>

      <h2>Hors-d’oeuvres</h2>

      <p>Charcuterie Box for 2<br/>
      
        genoa, cheeses, hot peppers, nuts, olives, berries, crisp breads, <Link to="/presidents-bash/charcuterie-box-for-2-ainpdfocw0" onClick={clearCurrentCategory}><span className="red">$12 each box</span></Link></p>

      <p>Fresh Open Face Jalapeno Poppers for 2<br/>

        Jalapeno, cream cheese, ranch seasoning, bacon & old cheddar, <Link to="/presidents-bash/fresh-open-face-jalapeno-poppers-for-2-aindy3sqc2" onClick={clearCurrentCategory}><span className="red">$12 each box</span></Link></p>

      <p>Hickory Bacon Wrapped Pineapple 10 pieces, <Link to="/presidents-bash/hickory-bacon-wrapped-pineapple-10-pieces-ain6bicbj0" onClick={clearCurrentCategory}><span className="red">$12 each box</span></Link></p>*/}

      <br />
      <br />

      <p>
        <strong>Pickup Location:</strong> <br />
        Wildwood Hospitality & Catering Services <br />
        Located at 551 Bryne Dr, Unit H, Barrie, ON L4N 9Y3, Canada
        <br />
        <a
          href="https://www.google.ca/maps/dir/Your+Location/551+Bryne+Dr,+Barrie,+ON+L4N+9Y3/@44.4742867,-79.7152997,11z/data=!4m8!4m7!1m0!1m5!1m1!1s0x882abc49d0922b5f:0xd72ab21c55d8c50c!2m2!1d-79.687307!2d44.3282321"
          target="_blank"
        >
          (click for driving directions)
        </a>
      </p>

      <p>
        <strong>Pickup Date:</strong> <br />
        Friday, June 18, 2021, 12 - 4 p.m.
      </p>
    </div>
  );
};

export default Banner;
