import React from "react";
import CartValidationErrors from "../AC-ProductPage/Components/CartValidationErrors/CartValidationErrors";
import { Link } from "gatsby";

import raffleLogo from "../../assets/img/raffleLogo.jpg";
import bellLogo from "../../assets/img/sponsors/bellLogo.png";
import theSarjeantLogo from "../../assets/img/sponsors/theSarjeantLogo.png";
import northernPlumbingLogo from "../../assets/img/sponsors/northernPlumbingLogo.png";
import jeffWaltersJewellers from "../../assets/img/sponsors/jeffWaltersJewellers.png";
import avettiLogo from "../../assets/img/sponsors/avettiLogo.png";
import sponsorLogo2 from "../../assets/img/sponsors/Rock95-logotagline.png";
import sponsorLogo3 from "../../assets/img/sponsors/KoolfFM-logotagline.png";

const Banner = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearCurrentCategory = () => {
    localStorage.removeItem("currentIndex");
    localStorage.removeItem("currentCategory");
  };

  return (
    <div className="text-center presidentsBash">
      <br />
      <br />

      <h1>FIRST LADY FUNDRAISER 2021!</h1>

      <br />
      <br />

      <p>
        Members Only Sale of
        <br />
        <strong>FREE RANGE / HORMONE & ANTIBIOTIC FREE FLASH FROZEN</strong>
        <br />
        Chickens and Farm Fresh Brown Shell Eggs
      </p>

      <br />

      <p>
        <strong>Pickup Location:</strong> <br />
        81 Patterson Rd, Barrie, ON L4N 3V9, Canada
        <br />
        <a
          href="https://www.google.ca/maps/dir/Your+Location/81+Patterson+Rd,+Barrie,+ON+L4N+3V9/@44.4850626,-79.7086279,11z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x4d2aa9268a2dddf3:0x21d718299ed1cc46!2m2!1d-79.4294664!2d44.6077025!1m5!1m1!1s0x882abd17833c590b:0xcca640fe6f8f656f!2m2!1d-79.7070768!2d44.3650239"
          target="_blank"
        >
          (click for driving directions)
        </a>
      </p>

      <p>
        <strong>Pickup Date:</strong> <br />
        Every Wednesday of the order week
      </p>
    </div>
  );
};

export default Banner;
