import React from "react";

import encodeConverter from "../../../../functions/htmldecoder";
import Grid from "../../../AC-UI-Elements/Grid/Grid";
import { IMAGE_VERSION, PROJECT_LINK } from "../../../../project-config";
import { useLocation } from "@reach/router";
import CategoryBreadcrumb from "../../../AC-Breadcrumb/CategoryBreadcrumb.jsx";
import { htmlDecode } from "../../../../functions/htmldecoder";
// import ExternalContentFromCMS from "../../../AC-ExternalContentFromCMS/ExternalContentFromCMS";

import * as classes from "../CategoryHeader/Styles/BDCategoryHeader.module.css";

export default function CategoryHeader({ data }) {
  const location = useLocation();
  const categoryImageState = data.image;
  console.info("borop data", data);

  return (
    <React.Fragment>
      <div
        className="sub-nav-wrapper"
        style={{
          background: `url(https://ik.imagekit.io/ofb/store${categoryImageState}?tr=pr-true,f-auto${
            IMAGE_VERSION ? `&${IMAGE_VERSION}` : ""
          })`
        }}
      >
        <div className="sub-nav-menu">
          <div className="sub-nav-title-desc-wrapper">
            <Grid container="true" justify="space-between" alignItems="center">
              <Grid item="true" xs={12}>
                <h2
                  style={{ backgroundColor: "transparent" }}
                  className="sub-nav-menu-title"
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(data.description)
                  }}
                ></h2>
              </Grid>
            </Grid>
          </div>
        </div>
        <CategoryBreadcrumb />
      </div>
      {/*<ExternalContentFromCMS*/}
      {/*  key="StoreCategoryHeaderGatsby"*/}
      {/*  place="home"*/}
      {/*  position="Bottom"*/}
      {/*  renderedBy="StoreCategoryHeaderGatsby"*/}
      {/*/>*/}
    </React.Fragment>
  );
}
