import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

const Banner = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopup = () => {
    setPopupOpen(current => !current);
  };

  return (
    <div>
      <div id="spellmanGrid">
        <div id="spellmanGridTop">
          <div>
            <p className="small">THE RHAPSODY OF</p>
            <p className="large">Leo Spellman</p>

            <Link
              className="spellmanTicketBtn"
              to="/spellman/the-rhapsody-of-leo-spellman-ticket-ainw89dac0"
            >
              Buy Tickets
            </Link>
          </div>
          <div>
            <img src="https://ik.imagekit.io/ofb/RT/spellman_lnxl6dvuTe.png?updatedAt=1711377994208" />
          </div>
        </div>
        <div id="spellmanGridBottom">
          <p className="large">
            Paul Hoffert (founder of the rock super-group “Lighthouse”), his
            wife Brenda and son David bring to the screen the astounding story
            of this Holocaust survivor and accomplished musician.
          </p>

          <p>
            Having survived the Holocaust, Spellman wrote a three-part symphony
            – emotionally telling of life before the Nazis, his years of
            suffering during the war, and his liberation.
          </p>

          <p>
            The music, lost for over 60 years was staged by the Hofferts and
            presented in concert to a standing ovation and a thrilled Spellman,
            6 months before his death at 98 years old.
          </p>

          <p>
            The movie, winner of several awards, and produced with the help of
            the Rotary Club of Barrie and Barrie’s Am Shalom Synagogue will be
            presented by the Hofferts in a special evening fundraiser for those
            same two organizations.
          </p>

          <p>
            The evening will include an introduction by the Hofferts, a showing
            of the film, q and a following the movie, and music by some popular
            Barrie musicians, who will be joined by a very special guest, food
            by Marco of the North Restaurant and a cash bar.
          </p>

          <p>
            Am Shalom Synagogue
            <br />
            Thursday May 9th, 7pm
          </p>

          <p>Tickets $89. Seating is limited. Purchase early.</p>

          <Link
            className="spellmanTicketBtn"
            to="/spellman/the-rhapsody-of-leo-spellman-ticket-ainw89dac0"
          >
            Buy Tickets
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
