import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

const Banner = ({categoryItems,data}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopup = () => {
    setPopupOpen(current => !current);
  };

  const handleChooseItem = (index) => {
    let currentCategory = {
      items: categoryItems,
      url: data.url
    };
    /*  categoryItems.map(c => {
      currentCategory.items.push(c.url);
    }); */
    localStorage.setItem("currentCategory", JSON.stringify(currentCategory));
    localStorage.setItem("currentIndex", index);
    // dispatch(setCurrentCategory(
    //   {
    //     currentIndex: index,
    //     categoryItems: {...categoryItems},
    //     supplier: {...supplier}
    //   }
    // ));
  };

  return (
    <div>
      <div id="SCTitle">
        <h1>Sonic Coalition</h1>
        <h2>
          <strong> <span className="sonicGreen">NEW DATE!</span> Thursday</strong><br/>
          August 24 @ 8:30PM
        </h2>
      </div>
      <div id="SCImg">
        <img src="https://ik.imagekit.io/ofb/RT/Sonic_Coalition_1__YF5qkIrn7.jpg?updatedAt=1684270162849"/>
      </div>

      <div id="SCInfoGrid">
        <div id="SCInfoLeft">
          <p><span className="large">ROCK ON</span> AND RAISE FUNDS FOR THE ROTARY CLUB OF BARRIE</p>
          <p><span className="donaleighs">DONALEIGH’S</span> 28 Dunlop St. East, Barrie</p>
        </div>
        <div id="SCInfoCenter">
          <p><strong>Tickets:</strong> $20.00 each (at barrierotary.com or at the door). <strong className="sonicGreen">Show starts at 8:30 PM Doors open at 7:30 PM</strong>. For more information visit <strong>barrierotary.com</strong></p>
        </div>
        <div id="SCInfoRight">
          <img src="https://ik.imagekit.io/ofb/RT/logoRT-fee708ed8eea34906857d94d7e627335_YUOIN4uS8S.png?updatedAt=1647960203172" />
          <img src="https://ik.imagekit.io/ofb/RT/donaleighslogo_Wq3CFUiXuf.jpeg?updatedAt=1684352937473" />
        </div>
      </div>

      <div id="earthDayBtn">

          <p><a href="/sonic/sonic-coalition-concert-ticket-ainy5t48a2">Get your Ticket Today</a></p>

        </div>
    </div>
  );
};

export default Banner;
