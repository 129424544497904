import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import euchreLogo from "../../assets/img/euchreCatTop.png";

import Img from "gatsby-image";

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      auctionTop: file(relativePath: { eq: "auctionTop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 950) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      auctionSupportOf: file(relativePath: { eq: "auctionSupportOf.png" }) {
        childImageSharp {
          fluid(maxWidth: 950) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      auctionPrizes: file(relativePath: { eq: "auctionPrizes.png" }) {
        childImageSharp {
          fluid(maxWidth: 950) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      auctionProjects: file(relativePath: { eq: "auctionProjects.png" }) {
        childImageSharp {
          fluid(maxWidth: 950) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopup = () => {
    setPopupOpen(current => !current);
  };

  return (
    <div>
      <div className="euchreTop">
        <div className="euchreText">
          <img className="rtLogo" src="https://ik.imagekit.io/ofb/RT/RTLogoWhite_2gvpLAkaK.png?updatedAt=1679693202400"/>
          <h1>Charity Euchre Tournament</h1>

          <p className="large"><strong>March 9, 2024, 6pm to 11:30pm</strong></p>
          <p className="address"><strong>Southshore Centre<br/>
          205 Lakeshore Dr, Barrie, ON L4N 7Y9</strong></p>


          <p className="large"><strong>The event will have: Live Music, Food and Cash Bar</strong></p>

          <div className="etGrid">

      <div>
          <p><strong className="entryFee">Schedule</strong></p>

          <table>
            <tr>
              <th>Check-In:</th>
              <td>6pm to 6:45pm</td>
            </tr>
            <tr>
              <th>Tournament:</th>
              <td>6:45pm to 10pm</td>
            </tr>
            <tr>
              <th>Winners Announced:</th>
              <td>11pm</td>
            </tr>
          </table>
          </div>
          <div>
          <p><strong className="entryFee">Team Cash Prizes</strong></p>

          <table>
            <tr>
              <th>1st Place</th>
              <td>$600</td>
            </tr>
            <tr>
              <th>2nd Place</th>
              <td>$300</td>
            </tr>
            <tr>
              <th>3rd Place</th>
              <td>$200</td>
            </tr>
            <tr>
              <th>4th Place</th>
              <td>$100</td>
            </tr>
          </table>
          </div>
          <div>
          <p><strong className="entryFee">Entry Fee</strong></p>

          <table>
            <tr>
              <th>Single:</th>
              <td>$50</td>
            </tr>
            <tr>
              <th>Team of 2:</th>
              <td>$100</td>
            </tr>
          </table>
          </div>
          </div>


          <div className="etDetails">
          <p>Online Registration  Will  Be Open From: February 1st to March 6th</p>
          <p>(Tournament is limited to the first 160 paid registrants walk-ins are welcome if space is available) </p>
          <p>Euchre Tournament Style Will Be Grand Prix Each Team Will Play 6 Games</p>
          <p>Each Game will Be 25 Minutes the team that's has the most points wins. If tied, the win would go to the team first making the tying point. </p>
          <p>After all 6 games played  tournament winners will be determined by total wins if there is a tie .  The tie will be broken by total points of the six games.  (Max Points per game can be 13 so it can be advantageous to go alone when you have 8 or 9 points) </p>
          </div>

          <div className="etBtn">
            <Link to={'/virtual-euchre-tournament/tournament-entry-fee-aina6v7g52/'}>SIGN UP TODAY</Link>
          </div>

          
        </div>
        <div className="euchreImg">
          {/* <img className="img-responsive" src="https://ik.imagekit.io/ofb/RT/etBackCards_-s9jDg6aN.png?updatedAt=1705529060783" /> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
