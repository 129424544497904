import React from "react";
import CartValidationErrors from "../AC-ProductPage/Components/CartValidationErrors/CartValidationErrors";
import { Link } from "gatsby";

import hockeyImg from "../../assets/img/HP1000.png";
import TDLogo from "../../assets/img/SP-TDBANK.png";
// import mcdonaldsLogo from "../../assets/img/SP-MCDONALDS.png";
import coltsLogo from "../../assets/img/SP-COLTS.png";
import currieLogo from "../../assets/img/SP-CURRIE.png";
import sargentLogo from "../../assets/img/halloweenSpon6.png";
import paulSadlonLogo from "../../assets/img/paulSadlonLogo.png";
import sunbeltLogo from "../../assets/img/sunbeltLogo.jpg";
import ghcLogo from "../../assets/img/ghcLogo.jpg";
import prostar from "../../assets/img/prostar.jpg";
import swm from "../../assets/img/swm.jpg";

const Banner = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearCurrentCategory = () => {
    localStorage.removeItem("currentIndex");
    localStorage.removeItem("currentCategory");
  };

  return (
    <div>
      <div className="hockeyRaffleCenter">
        <div class="col-md-6">
          <img className="img-responsive" src={hockeyImg} />

          <Link to="/hockey-pool/rotary-hockey-pool-ainllmd142">
            Get a Hockey Pool Ticket Today
          </Link>
        </div>

        <div className="col-md-6">
          <h1 className="text-center">Hockey Draft 2022-2023 Regular Season</h1>

          <p>
            Thursday, Oct 20th, 2022 Doors Open at 6:30 pm
            <br />
            South Shore Centre – Lakeshore Dr., Barrie
            <br />
            Leafs VS Capitals Game starting at 7 pm
          </p>

          <p>
            <strong>$85.00 per person</strong>
            <br />
            (price includes Buffet Dinner and Hockey draft entry)
            <br />
            Cash Bar
            <br />
            Seating is limited to the first 150 participants
          </p>

          <p>
            20 Awesome Raffle prizes available to be won,
            <br />
            the raffle tickets are sold the night of the event.
            <br />
            Cash prizes for the top 10 entries to be paid out at the end of the
            Year
          </p>

          <p>
            <strong>Please Note:</strong> You MUST register and pay in advance.
            <br />
            Payment accepted via Credit Card only
          </p>

          {/* <p>Registrations will only be accepted up until Oct.18th 2022</p> */}
        </div>

        <div class="clearfix"></div>
      </div>

      <div className="row">
        <div id="sponsors">
          <div>
            <h2
              className="rafflePrizeTitle"
              style={{ textAlign: "center", marginLeft: "5%" }}
            >
              Thank You To Our Sponsors
            </h2>
          </div>
          <div className="raffleSponsor">
            <div className="sponsorImg" style={{ width: "350px" }}>
              <a href="http://www.tdcanadatrust.com/" target="_blank">
                <img className="img-responsive" src={TDLogo} />
              </a>
            </div>
            <div className="sponsorImg">
              <a href="https://www.paulsadlon.com/" target="_blank">
                <img className="img-responsive" src={paulSadlonLogo} />
              </a>
            </div>
            <div className="sponsorImg" style={{ width: "250px" }}>
              <a
                href="https://www.georgianheatingandcooling.com/"
                target="_blank"
              >
                <img className="img-responsive" src={ghcLogo} />
              </a>
            </div>
          </div>

          <div className="raffleSponsor">
            <div className="sponsorImg" style={{ width: "150px" }}>
              <a href="http://www.currietruckcentre.com/" target="_blank">
                <img className="img-responsive" src={currieLogo} />
              </a>
            </div>

            <div className="sponsorImg" style={{ width: "250px" }}>
              <a href="http://www.barrielacrossehockey.ca/" target="_blank">
                <img className="img-responsive" src={prostar} />
              </a>
            </div>

            <div className="sponsorImg" style={{ width: "250px" }}>
              <a href="https://monteitholdfield.com/" target="_blank">
                <img className="img-responsive" src={swm} />
              </a>
            </div>
            {/* <div className="sponsorImg" style={{ width: "150px" }}>
              <a href="http://www.mcdonalds.com/" target="_blank">
                <img className="img-responsive" src={mcdonaldsLogo} />
              </a>
            </div> */}
            {/* <div className="sponsorImg" style={{ width: "150px" }}>
              <a href="http://www.barriecolts.com/" target="_blank">
                <img className="img-responsive" src={coltsLogo} />
              </a>
            </div> */}
            <div className="sponsorImg" style={{ width: "150px" }}>
              <a href="https://www.sarjeants.com/" target="_blank">
                <img className="img-responsive" src={sargentLogo} />
              </a>
            </div>
            <div className="sponsorImg" style={{ width: "350px" }}>
              <a href="https://www.sunbeltrentals.com/" target="_blank">
                <img className="img-responsive" src={sunbeltLogo} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
