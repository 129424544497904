// extracted by mini-css-extract-plugin
export var collectionsWrapper = "BDCategory-module--collectionsWrapper--I2u-r";
export var mainImage = "BDCategory-module--mainImage--oeexR";
export var mobileFilterBtn = "BDCategory-module--mobileFilterBtn--6ecnq";
export var numOfItemsWrapper = "BDCategory-module--numOfItemsWrapper--c7jP-";
export var otherImage = "BDCategory-module--otherImage--Hb7DC";
export var placeHolderWrap = "BDCategory-module--placeHolderWrap--z7GDx";
export var sortByPagination = "BDCategory-module--sortByPagination--yIF1H";
export var subWrapper = "BDCategory-module--subWrapper--03wbW";
export var wrapper = "BDCategory-module--wrapper--3e4+w";
export var wrapper2 = "BDCategory-module--wrapper2--wuqYn";