import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "gatsby";
import {
  dispatchCurrentPage,
  nextPage
} from "../../../redux/actions/paginationActions.js";

import { I18nContext } from "../../../i18n/index";
import * as classes from "../../AC-Pagination/Styles/BDPagination.module.css";

const Pagination = ({ data, pageContext, setScrollPos }) => {
  const dispatch = useDispatch();
  const { translate } = React.useContext(I18nContext);
  const pagesState = Array.from({ length: pageContext.pageCount }).map(
    (i, index) => index + 1
  );

  const currentPageState = pageContext.currentPage;

  const scroolPageState = useSelector(
    state => state.categoryReducer.scroolPage,
    shallowEqual
  );

  const cidState = data.cid;

  const loadingState = useSelector(
    state => state.categoryReducer.loading,
    shallowEqual
  );

  const handlePageLink = state => {
    if (state === "next") {
      if (currentPageState === 1) {
        return `/${data.url}/${currentPageState + 1}/`;
      }
      return `/${data.url.replace(
        `/${currentPageState}`,
        `/${currentPageState + 1}`
      )}/`;
    } else if (state === "prev") {
      return `/${data.url.replace(
        `/${currentPageState}`,
        `${currentPageState - 1 === 1 ? "" : `/${currentPageState - 1}`}`
      )}/`;
    } else if (state === "last") {
      if (currentPageState === 1) {
        return `/${data.url}/${pageContext.pageCount}/`;
      }
      return `/${data.url.replace(
        `/${currentPageState}`,
        `/${pageContext.pageCount}`
      )}/`;
    } else return "";
    /*  currentPageState !== 1
    ? "/" +
      data.url.replace(`${currentPageState}`, "") +
      (currentPageState + 1) +
      "/"
    : "/" +
      data.url.replace(`${currentPageState}`, "") +
      "/" +
      (currentPageState + 1) +
      "/" */
  };

  const renderFirstAndLastButtons = (
    <React.Fragment>
      <Link
        onClick={() => setScrollPos(window.scrollY)}
        to={"/" + data.url.replace(`/${currentPageState}`, "/")}
      >
        <span className={`${classes.paginationBtn} "first buttonPageLeft"`}>
          {translate("js.category.pagination.first")}
        </span>
      </Link>
      <Link
        onClick={() => setScrollPos(window.scrollY)}
        to={handlePageLink("prev")}
      >
        <span className={`${classes.paginationBtn} "prev"`}>&lt;</span>
      </Link>
    </React.Fragment>
  );

  const handleNextPage = page => {
    if (scroolPageState < pagesState.length || page != 0) {
      dispatch(dispatchCurrentPage(page));
      dispatch(nextPage(page, cidState, false));
    } else {
      console.error("End of the page");
    }
  };
  console.log("pagesState", pagesState);
  return (
    <React.Fragment>
      {!loadingState && pagesState.length > 1 ? (
        <div
          tempStyle={{ float: "right", width: "auto", fontSize: "15px" }}
          className="pagination-container"
        >
          <div id="pagination" className={classes.pagination}>
            {currentPageState > 1 ? renderFirstAndLastButtons : null}

            {pagesState.map(page => {
              if (
                page <= currentPageState + 5 &&
                page >= currentPageState - 5
              ) {
                return (
                  <Link
                    onClick={() => setScrollPos(window.scrollY)}
                    key={page}
                    to={
                      currentPageState !== 1
                        ? "/" +
                          data.url.replace(`/${currentPageState}`, "/") +
                          `${page === 1 ? "" : page + "/"}`
                        : "/" +
                          data.url +
                          "/" +
                          `${page !== 1 ? page + "/" : ""}`
                    }
                  >
                    <span
                      key={page}
                      className={`${classes.paginationBtn} ${
                        currentPageState == page ? classes.selected : ""
                      }`}
                      style={
                        currentPageState == 1 && page == 1
                          ? { borderLeft: "unset" }
                          : null
                      }
                    >
                      {page}
                    </span>
                  </Link>
                );
              }
            })}
            {currentPageState == pagesState[pagesState.length - 1] ? null : (
              <React.Fragment>
                <Link
                  onClick={() => setScrollPos(window.scrollY)}
                  to={handlePageLink("next")}
                >
                  <span className={`${classes.paginationBtn} next`}>&gt;</span>
                </Link>

                <Link
                  onClick={() => setScrollPos(window.scrollY)}
                  to={handlePageLink("last")}
                >
                  <span className={`${classes.paginationBtn} ${classes.last}`}>
                    {translate("js.category.pagination.last")}
                  </span>
                </Link>
              </React.Fragment>
            )}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Pagination;
