import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import Img from "gatsby-image";

import halloweenSpon1 from "../../assets/img/halloweenSpon1.png";
import halloweenSpon2 from "../../assets/img/halloweenSpon2.png";
import halloweenSpon3 from "../../assets/img/halloweenSpon3.png";
import halloweenSpon4 from "../../assets/img/halloweenSpon4.png";
import halloweenSpon5 from "../../assets/img/halloweenSpon5.png";
import halloweenSpon6 from "../../assets/img/halloweenSpon6.png";
import halloweenSpon7 from "../../assets/img/halloweenSpon7.png";
import halloweenSpon8 from "../../assets/img/halloweenSpon8.png";
import halloweenSpon9 from "../../assets/img/halloweenSpon9.png";
import halloweenSpon10 from "../../assets/img/halloweenSpon10.png";
import halloweenSpon11 from "../../assets/img/halloweenSpon11.png";
import halloweenSpon12 from "../../assets/img/halloweenSpon12.png";

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      Image1: file(relativePath: { eq: "halloweenBanner.png" }) {
        childImageSharp {
          fluid(maxWidth: 950) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImageDoor: file(relativePath: { eq: "halloweenDoor.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImageHouse: file(relativePath: { eq: "halloweenHouse.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImagePumpkin: file(relativePath: { eq: "halloweenPumpkin.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImageSaturday: file(relativePath: { eq: "halloweenSaturday.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImagePumpPurch: file(relativePath: { eq: "halloweenPumpPurch.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImagePrize: file(relativePath: { eq: "halloweenHousePrize.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImageDoorPrize: file(relativePath: { eq: "halloweenDoorPrize.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImagePumpkinPrize: file(
        relativePath: { eq: "halloweenPumpkinPrize.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon1: file(relativePath: { eq: "halloweenSpon1.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon2: file(relativePath: { eq: "halloweenSpon2.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon3: file(relativePath: { eq: "halloweenSpon3.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon4: file(relativePath: { eq: "halloweenSpon4.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon5: file(relativePath: { eq: "halloweenSpon5.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon6: file(relativePath: { eq: "halloweenSpon6.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon7: file(relativePath: { eq: "halloweenSpon7.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon8: file(relativePath: { eq: "halloweenSpon8.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon9: file(relativePath: { eq: "halloweenSpon9.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon10: file(relativePath: { eq: "halloweenSpon10.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon11: file(relativePath: { eq: "halloweenSpon11.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon12: file(relativePath: { eq: "halloweenSpon12.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      halloweenSpon13: file(relativePath: { eq: "halloweenSpon13.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      halloweenSpon14: file(relativePath: { eq: "halloweenSpon14.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      halloweenSpon15: file(relativePath: { eq: "halloweenSpon15.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      halloweenSpon16: file(relativePath: { eq: "halloweenSpon16.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      halloweenSpon17: file(relativePath: { eq: "halloweenSpon17.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      halloweenSpon18: file(relativePath: { eq: "halloweenSpon18.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      halloweenSpon19: file(relativePath: { eq: "halloweenSpon19.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon20: file(relativePath: { eq: "halloweenSpon20.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      halloweenSpon21: file(relativePath: { eq: "halloweenSpon21.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  console.log("data33", data);

  return (
    <div className="halloweenContestTop">
      <div id="halloweenBanner">
        <Img fluid={data.Image1.childImageSharp.fluid} />
      </div>

      <div class="prizeRow">
        <div id="bestHouse" class="prizeBack">
          <div class="prizeTitle">
            <Link to="/halloween-contest/best-house-ain2a3ly53">
              Best <br /> House
            </Link>
          </div>

          <div class="entryFee">
            <p>Entry Fee $25</p>
          </div>

          <Link to="/halloween-contest/best-house-ain2a3ly53">
            <Img fluid={data.ImageHouse.childImageSharp.fluid} />
          </Link>
        </div>

        <div id="bestHouseWin" class="prizeBack winBox">
          <div class="prizeTitle">Win</div>

          <Img fluid={data.ImagePrize.childImageSharp.fluid} />

          <p className="prizeText">Napoleon Rogue 525 Propane Grill</p>
        </div>

        <div id="bestDoor" class="prizeBack">
          <div class="prizeTitle">
            <Link to="/halloween-contest/best-house-door-ainztmx493">
              Best <br /> House Door
            </Link>
          </div>

          <div class="entryFee">
            <p>Entry Fee $15</p>
          </div>

          <Link to="/halloween-contest/best-house-door-ainztmx493">
            <Img fluid={data.ImageDoor.childImageSharp.fluid} />
          </Link>
        </div>

        <div id="bestDoorWin" class="prizeBack winBox">
          <div class="prizeTitle">Win</div>

          <Img fluid={data.ImageDoorPrize.childImageSharp.fluid} />

          <p className="prizeText">Bradford Greenhouse Gift Card</p>
        </div>

        <div id="bestPumpkin" class="prizeBack">
          <div class="prizeTitle">
            <Link to="/halloween-contest/best-pumpkin-ainfa5q742">
              Best <br /> Pumpkin
            </Link>
          </div>

          <div class="entryFee">
            <p>Entry Fee $10</p>
          </div>

          <Link to="/halloween-contest/best-pumpkin-ainfa5q742">
            <Img fluid={data.ImagePumpkin.childImageSharp.fluid} />
          </Link>
        </div>

        <div id="bestPumpkinWin" class="prizeBack winBox">
          <div class="prizeTitle">Win</div>

          <Img fluid={data.ImagePumpkinPrize.childImageSharp.fluid} />

          <p className="prizeText">Napoleon Cinema 24 Log Electric Fireplace</p>
        </div>

        <div id="spookySaturday" class="prizeBack">
          <div class="prizeTitle">
            Spooky <br /> Saturday
          </div>

          <Img fluid={data.ImageSaturday.childImageSharp.fluid} />

          <p className="eventDetails">
            Join us for spooky family fun at{" "}
            <strong>Meridian Place, Sat Oct 30, 11am-3pm.</strong>
            <br />
            <br />
            Includes pumpkin carving, children’s costume & pizza contests, music
            games & more!
          </p>
        </div>

        <div id="pumpkinPurchase" class="prizeBack">
          <div class="prizeTitle">
            <Link to="/halloween-contest/pumpkin-delivered-to-your-home-ainvm89ly2">
              Pumpkin <br /> Purchase
            </Link>
          </div>

          <Link to="/halloween-contest/pumpkin-delivered-to-your-home-ainvm89ly2">
            <Img fluid={data.ImagePumpPurch.childImageSharp.fluid} />
          </Link>

          <p className="eventDetails">
            <strong>Order your perfect pumpkin</strong> delivered to your door
            for only $10 at the link below!
          </p>
        </div>
      </div>

      <div className="contestDetails">
        <p>
          Join the Rotary Club of Barrie in celebrating and decorating the City
          of Barrie for Halloween and WIN BIG! Choose between decorating your
          house, your door or carve a pumpkin. One registration/entry per Barrie
          address, winners of each category must be a resident of Barrie. Load
          your pictures at shop.barrierotary.com/halloweencontest and delight
          the citizens of Barrie with your creativity and spirit. Donations
          accepted for entries with tax receipt of $2O.OO or more. Contest
          closes October 30, 2021 3:00pm. Judging occurs October 31, 2021.
        </p>

        <p>
          We kindly ask all entrants and participants to take proper safety measures in decorating and carving, and to follow all local Covid-19 public health guidelines while participating in these events.
        </p>
      </div>

      <div className="halloweenSponsorGrid">
        <div className="sponGridRow">
          <div>
            <Img fluid={data.halloweenSpon1.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon2.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon3.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon4.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon5.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon6.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon7.childImageSharp.fluid} />
          </div>
        </div>

        <div className="sponGridRow">
          <div>
            <Img fluid={data.halloweenSpon8.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon9.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon10.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon11.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon12.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon13.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon14.childImageSharp.fluid} />
          </div>
        </div>

        <div className="sponGridRow lastRow">
          <div>
            <Img fluid={data.halloweenSpon15.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon16.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon17.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon18.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon19.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon20.childImageSharp.fluid} />
          </div>

          <div>
            <Img fluid={data.halloweenSpon21.childImageSharp.fluid} />
          </div>
        </div>

      </div>
      <div class="clearfix" style={{marginBottom: "50px"}}></div>
    </div>
  );
};

export default Banner;
