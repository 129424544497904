import React, { useState } from "react";
import Modal from "../AC-UI-Elements/Modal/Modal";
import TypoGraphy from "../AC-UI-Elements/TypoGraphy/TypoGraphy";

import raffleStream from "../../assets/img/raffleStream.jpg";

const linkToShow = (
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/F4ZuwWqd7Xg"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const paper = {
  position: "absolute",
  width: 640,
  // backgroundColor: theme.palette.background.paper,
  // boxShadow: theme.shadows[5],
  // padding: theme.spacing(4),
  outline: "none"
};

function SimpleModal() {
  const [open, setOpen] = useState(false);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const CustomModal = () => {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={{ modalStyle, ...paper }}>
          <TypoGraphy variant="subtitle1" id="simple-modal-description">
            {linkToShow}
          </TypoGraphy>
          {/* <SimpleModal /> */}
        </div>
      </Modal>
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <p>Watch the Draw Now Below:</p>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <a
            onClick={() => {
              handleOpen();
            }}
          >
            <img className="img-responsive" src={raffleStream} />
          </a>
        </div>
      </div>
      <CustomModal />
    </>
  );
}

export default SimpleModal;
