/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useLayoutEffect
} from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useLocation } from "@reach/router";

import Grid from "../AC-UI-Elements/Grid/Grid";
import "../../assets/css/menu.css";
import "../../assets/css/minified.css";

import CategoryItems from "./components/CategoryItems/BDCategoryItems";
import Facets from "./components/AC-Facets/BDFacets";
import Pagination from "./components/BDPagination";
import PaginationRegular from "../AC-Pagination/BDPagination";

import NumberOfItems from "./components/NumberOfItems";
import Loading from "../AC-Loading/Loading.jsx";
import FacetBreadcrumb from "../AC-Breadcrumb/BDFacetBreadcrumb.jsx";
import SortBy from "./components/SortBy";
import Facets_Mobile from "../AC-Facets/Facets_Mobile.jsx";

import * as classes from "./styles/BDCategory.module.css";

import {
  dispatchScroolPage,
  nextPage,
  nextPageGatsby,
  nextPageScrool
} from "../../redux/actions/paginationActions.js";
import { I18nContext } from "../../i18n";

import CategoryHeader from "./components/CategoryHeader/BDCategoryHeader";
import {
  gatsbyFetchCategory,
  backToCategoryNormalize,
  fetchCategoryFromDirectUrl,
  fetchingCategorySuccess,
  fetchingCategoryFailure,
  showDynamicAction
} from "../../redux/actions/categoryActions.js";
import StoreCategoryHeader from "../AC-Stores/components/StoreCategoryHeaderStore";
import { setCategoryNavCatAction } from "../../redux/actions/menuActions";
import NoItems from "./components/NoItems/NoItems";
import { renderPlaceholderCategoryItems } from "../AC-CategoryPage/components/CategoryItems/CategoryItems";
import { handleScroolPosition } from "../../redux/actions/mainActions";
import {
  fetchingFilterFailure,
  fetchingFilterRequest,
  fetchingFilterSuccess,
  newFilterFacet
} from "../../redux/actions/facetActions";
import {
  CATEGORY_FETCH_LINK,
  CATEGORY_PAGING_FETCH_LINK
} from "../../redux/links";
import buttonsMapping from "../../functions/buttonsMapping";
import mapDynamicFacetSliders from "../../functions/mapDynamicFacetSliders";
import { mapCategoryUrls } from "../../redux/actions/productAction";
import { navigate } from "gatsby";
import IFRBanner from "../IceFishingRaffle/IFRBanner";
import Banner from "../IceFishingRaffle/IFRBanner";
import PBTop from "../PresidentsBash/PBTop";
import FLTop from "../FirstLady/FLTop";
import bellLogo from "../../assets/img/sponsors/bellLogo.png";
// import ErrorMessage from "../ErrorMessage/ErrorMessage";
import IHTop from "../IceHockey/IHTop";
import HCTop from "../HalloweenContest/HCTop";
import SATop from "../SilentAuction/SATop";
import FRTop from "../FunRun/FRTop";
import SCTop from "../SonicCoalition/SCTop";
import ETTop from "../EuchreTourn/ETTop";
import STTop from "../SpringTonic/STTop";
import SMTop from "../Spellman/SMTop";
import EDTop from "../EarthDay/EDTop";
import { MdFilterList } from "react-icons/md";
import { PROJECT_LINK, VID } from "../../project-config";
import { setDynamicContentAction } from "../../redux/actions/geoLocationActions";

function BDCategory({ data, pageContext, navCategory, supplier }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  const itemId = state && state?.itemId;
  console.log("itemCardstate23", state, itemId);
  const [scrollPos, setScrollPos] = useState(null);

  const containerRef = useRef(null);
  const backButtonState = useSelector(
    state => state.categoryReducer.backButton,
    shallowEqual
  );
  console.info("borop category data2", data);

  const showDynamicState = useSelector(
    state => state.categoryReducer.showDynamic,
    shallowEqual
  );

  const initialLoadState = useSelector(
    state => state.mainReducer.initialLoad,
    shallowEqual
  );

  useEffect(() => {
    if (scrollPos && typeof window !== "undefined") {
      console.log("asd123456asdsa");
      dispatch(setDynamicContentAction(false));
      setTimeout(() => {
        window.scrollTo(0, scrollPos);
      }, 50);
    }
  }, [scrollPos]);

  const [categoryItems, setCategoryItems] = useState(data.items);

  useEffect(() => {
    dispatch(setCategoryNavCatAction(navCategory));
  }, []);

  useEffect(() => {
    let temp = location.state;
    let condition = true;
    if (temp !== null) {
      if (Object.keys(temp).includes("cat")) {
        condition = false;
      }
    }
    if (
      !initialLoadState &&
      Object.keys(location).includes("state") &&
      condition
    ) {
      if (!backButtonState) {
        dispatch(gatsbyFetchCategory(data, pageContext, data.url));
      } else {
        dispatch(backToCategoryNormalize());
      }

      if (showDynamicState && !backButtonState) {
        dispatch(fetchCategoryFromDirectUrl());
      }
    }
  }, [initialLoadState]);

  const filterUrlState = useSelector(
    state => state.facetReducer.filterUrl,
    shallowEqual
  );

  useEffect(() => {
    if (
      Object.keys(location).includes("state") &&
      typeof location.state === "object" &&
      location.state !== null
    ) {
      // console.log("!!!!!!!!HEY", location);
      if (Object.keys(location.state).includes("cat")) {
        console.log(
          "LOCATION 2021",
          location.state,
          `&${location.state.cat}`,
          `${filterUrlState}&${location.state.cat}`
        );
        dispatch(fetchingFilterRequest());
        fetch(location.state.url)
          .then(res => res.json())
          .then(json => {
            let tempages = [];
            for (let i = 1; i <= Number(json[0].numOfPages); i++) {
              tempages.push(i);
            }
            let numberOfItems = Number(json[4].itemsCount);
            let categoryItems = json[1].items.map(item => {
              const newObj = Object.assign({}, item);
              // update the new object
              let image = newObj.image;
              newObj.image = image.replace("thumbnails", "images");
              return newObj;
            });

            let priceButtonsTemp = buttonsMapping(json);
            Object.keys(priceButtonsTemp.Category).map((l, index) => {
              if (index === 0) {
                priceButtonsTemp.Category[l] = false;
              }
            });
            let dynamicSlider = {};
            dynamicSlider = mapDynamicFacetSliders(json);

            // let filterUrl = url;
            dispatch(
              fetchingCategorySuccess(
                json,
                location.state.cid,
                location.state.cat,
                tempages,
                categoryItems,
                priceButtonsTemp,
                dynamicSlider,
                [],
                location.state.url,
                location.state.url,
                `&${location.state.cat}`,
                location.state.cid,
                numberOfItems
              )
            );
            dispatch(showDynamicAction(true));
          })
          .catch(error => {
            console.error("fetching filter failure", error);
            dispatch(fetchingCategoryFailure(error));
          });
      }
    }
  }, [location]);

  const { translate } = useContext(I18nContext);
  const [filterButtonClicked, setFilterButtonClicked] = useState(false);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loadingBottom = useSelector(
    state => state.categoryReducer.loadingBottom,
    shallowEqual
  );

  const pagesState = useSelector(
    state => state.categoryReducer.pages,
    shallowEqual
  );

  const noItemFoundState = useSelector(
    state => state.categoryReducer.noItemFound,
    shallowEqual
  );

  const loading = useSelector(
    state => state.categoryReducer.loading,
    shallowEqual
  );

  const currentPageState = useSelector(
    state => state.categoryReducer.currentPage,
    shallowEqual
  );

  const scroolPageState = useSelector(
    state => state.categoryReducer.scroolPage,
    shallowEqual
  );

  const cidState = useSelector(
    state => state.categoryReducer.cidN,
    shallowEqual
  );
  console.log("cidState1234567890", cidState);

  const breadState = useSelector(
    state => state.facetReducer.bread,
    shallowEqual
  );
  const urlFilterParamsState = useSelector(
    state => state.facetReducer.urlFilterParams,
    shallowEqual
  );

  const numberOfItemState = useSelector(
    state => state.categoryReducer.numberOfItems,
    shallowEqual
  );

  const numberOfItemsGridSizes = {
    xs: numberOfItemState === 0 ? 12 : 6,
    sm: numberOfItemState === 0 ? 12 : 6,
    md: numberOfItemState === 0 ? 12 : 3
  };
  const itemsFetchedState = useSelector(
    state => state.categoryReducer.itemsFetched,
    shallowEqual
  );

  const facetsState = useSelector(
    state => state.facetReducer.facets,
    shallowEqual
  );

  const scState = useSelector(state => state.categoryReducer.sc, shallowEqual);
  const scPosState = useSelector(
    state => state.categoryReducer.scPos,
    shallowEqual
  );

  console.log("scState", scState, scPosState);
  useEffect(() => {
    if (scState && scPosState > 0) {
      console.log("asd123bbbb");
      setTimeout(() => {
        window.scrollTo({
          top: Math.trunc(scPosState),
          left: 0
        });
        dispatch(nextPageScrool());
      }, 0);
    }
  }, [scState, scPosState]);

  const [nextPageState, setNextPageState] = useState([]);
  const [linkState, setLinkState] = useState({
    link: location.href,
    top: false
  });
  const fetchNextPageHandler = page => {
    let params = null;
    if (pageContext.url && pageContext.url.includes("stores")) {
      params = `Sellers=${data.name
        .split(" ")
        .map(l => {
          if (l == "JAM") {
            return "JAM";
          } else if (l.toLowerCase() === "4m") {
            return "4M";
          } else if (l !== "") {
            let storeTitle =
              l.charAt(0).toUpperCase() + l.slice(1).toLowerCase();
            return storeTitle;
          }
          return l;
        })
        .join("%20")
        .replace("�", "")}`;
    }
    return new Promise((res, rej) => {
      fetch(CATEGORY_PAGING_FETCH_LINK(data.cid, "en", page, params))
        .then(res => res.json())
        .then(json => {
          console.log(
            "FETCH NEXT PAGE",
            CATEGORY_PAGING_FETCH_LINK(data.cid, "en", page, params),
            data,
            pageContext,
            page,
            params
          );
          setNextPageState(json[1].items);
          setLinkState({ link: location.href, top: true });
          res();
        })
        .catch(err => rej(err));
    });
  };

  useEffect(() => {
    if (
      currentPageState &&
      !showDynamicState &&
      pageContext.pageCount > pageContext.currentPage
    ) {
      fetchNextPageHandler(pageContext.currentPage + 1);
    }

    dispatch(dispatchScroolPage(pageContext.currentPage, true));
  }, [currentPageState, cidState]);

  const handleNextPage = (e, page) => {
    let position = 0;
    if (e !== "") {
      position = window.pageYOffset;
    }

    if (
      scroolPageState < pagesState.length &&
      page !== 0 &&
      (breadState.length > 0 || urlFilterParamsState !== "")
    ) {
      dispatch(dispatchScroolPage(page));
      dispatch(nextPage(page, cidState, true));
    } else if (
      scroolPageState < pagesState.length &&
      page !== 0 &&
      breadState.length == 0
    ) {
      if (pageContext.pageCount > page) {
        fetchNextPageHandler(page + 1);
      }
      setLinkState({ link: location.href, top: false });
      dispatch(dispatchScroolPage(page));
      dispatch(nextPageGatsby(nextPageState, position));
    } else {
      console.info("End of the pagea");
    }
  };

  const handleFilterButtonClicked = () => {
    document.getElementById("___gatsby").setAttribute("aria-hidden", true);
    setFilterButtonClicked(true);
  };

  const handleFacetContentCloseIconClicked = () => {
    document.getElementById("___gatsby").setAttribute("aria-hidden", false);
    setFilterButtonClicked(false);
  };

  /*  useLayoutEffect(() => {
    if (!location.pathname.includes("stores")) {
      const { left, right } = mapCategoryUrls(location.pathname);
      if (typeof window !== undefined) {
        let start = 0;
        let end = 0;
        let startY = 0;
        let endY = 0;
        let touchTime = 0;
        let diff = 0;

        const touchStartEvent = e => {
          if (e.changedTouches[0]) {
            start = e.changedTouches[0].screenX;
            startY = e.changedTouches[0].screenY;
            touchTime = new Date();
          }
        };

        const touchEndEvent = e => {
          if (e.changedTouches[0]) {
            end = e.changedTouches[0].screenX;
            endY = e.changedTouches[0].screenY;
            diff = new Date() - touchTime;

            handleSwiper();
          }
        };

        const handleSwiper = () => {
          if (Math.abs(end - start) > Math.abs(endY - startY) && diff > 100) {
            if (end < start) {
              navigate(`/${left}`, { state: { directionCat: "left" } });
            } else if (end > start) {
              navigate(`/${right}`, { state: { directionCat: "right" } });
            }
          }
        };

        window.addEventListener("touchstart", touchStartEvent);

        window.addEventListener("touchend", touchEndEvent);

        return () => {
          window.removeEventListener("touchstart", touchStartEvent);
          window.removeEventListener("touchend", touchEndEvent);
        };
      }
    }
  }, []);
 */

  const renderMobileFacets = (
    <React.Fragment>
      <button
        className={classes.mobileFilterBtn}
        onClick={() => handleFilterButtonClicked()}
      >
        {translate("mobile_facets.filterButtonText")}
        <i className="material-icons">
          <MdFilterList />
        </i>
      </button>
      <Facets_Mobile
        filterButtonClicked={filterButtonClicked}
        handleFacetContentCloseIconClicked={handleFacetContentCloseIconClicked}
      />
    </React.Fragment>
  );

  const renderFacets = <Facets data={data} containerRef={containerRef} />;

  const myRef = useRef(null);

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      const scroolState = () => {
        let pageoffset = 0;
        if (typeof window !== "undefined") {
          pageoffset = window.pageYOffset;
        }
        dispatch(handleScroolPosition(pageoffset));
      };

      if (!loadingBottom && scroolPageState - currentPageState <= 2) {
        let handleOnScroll = () => {
          scroolState();
          if (!loading && myRef.current) {
            let scrollTop =
              (document.documentElement &&
                document.documentElement.scrollTop) ||
              document.body.scrollTop;
            let scrollHeight = myRef.current.offsetTop;
            let clientHeight =
              document.documentElement.clientHeight || window.innerHeight;
            let scrolledToBottom =
              Math.ceil(scrollTop + clientHeight) >= scrollHeight;

            if (scrolledToBottom) {
              handleNextPage("", scroolPageState + 1);
            }
          }
        };

        window.addEventListener("scroll", handleOnScroll);

        // returned function will be called on component unmount
        return () => {
          window.removeEventListener("scroll", handleOnScroll);
        };
      } else {
        if (typeof window !== "undefined") {
          window.addEventListener("scroll", scroolState);
        }
        // returned function will be called on component unmount
        return () => {
          if (typeof window !== "undefined") {
            window.removeEventListener("scroll", scroolState);
          }
        };
      }
    }
  });
  useEffect(() => {
    const currentAllProductCidState = JSON.parse(
      localStorage.getItem("allProductsByCategory")
    );
    console.log("currentAllProductCidState", currentAllProductCidState);
    if (
      currentAllProductCidState &&
      cidState !== "" &&
      cidState !== "undefined" &&
      currentAllProductCidState.cidState === cidState
    ) {
      return null;
    } else {
      let result = null;
      let allProductsByCategory = null;
      const asynFunc = async () => {
        result = cidState
          ? fetch(
              `${PROJECT_LINK}/store.html?vid=${VID}&cid=${cidState}&tpt=json_en&mpv=childItemsDTO&mpvp=1&pageItemsCount=1000`
            ).then(res => res.json())
          : null;

        allProductsByCategory = await result;
        if (allProductsByCategory) {
          allProductsByCategory = {
            items: allProductsByCategory?.[1]?.items.map(item => item.url),
            itemsLength: allProductsByCategory?.[1]?.items?.length,
            url: location.pathname
          };
          console.log("allProductsByCategory", cidState, allProductsByCategory);

          localStorage.setItem(
            "allProductsByCategory",
            JSON.stringify({ ...allProductsByCategory, cidState })
          );
        }
      };

      asynFunc();
    }
  }, [cidState]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (itemId) {
        setTimeout(() => {
          const scrollDiv = document.getElementById(itemId)?.offsetTop;
          console.log("asd12345", itemId, scrollDiv);
          window.scrollTo({ top: scrollDiv, behavior: "smooth" });
        }, 200);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [itemId]);

  const renderHeader = () => {
    if (location.pathname.includes("stores")) {
      return (
        <StoreCategoryHeader
          data={data}
          storeSellerData={supplier.length > 0 ? supplier[0] : undefined}
          pickupLocData={
            supplier.length > 0 ? supplier[0].pickup_locations : undefined
          }
          supplierShippingInfo={
            supplier.length > 0 ? supplier[0].shipping_information : undefined
          }
          sellerHaveNoProducts={categoryItems && categoryItems.length === 0}
        />
      );
    } else {
      return <CategoryHeader data={data} />;
    }
  };

  if (typeof window !== "undefined") {
    if (data.url === "halloween-contest") {
      navigate("/halloweencontest");
      return null;
    }
  }

  if (
    noItemFoundState &&
    cidState !== "search" &&
    !location.pathname.includes("stores")
  ) {
    return (
      <div id="bd">
        <div className="main">
          {renderHeader()}
          <div className="row">
            <div className="category-container">
              <NoItems />
              <div ref={myRef} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderCategoryContent = () => {
    if (categoryItems && categoryItems.length > 0) {
      return (
        <div className="row">
          <div
            id="category-container"
            className="category-container"
            ref={containerRef}
          >
            {/*   <DistanceFilter /> */}

            <Grid container="true" justify="space-between" alignItems="center">
              <Grid
                item="true"
                xs={6}
                sm={4}
                md={4}
                lg={3}
                className="grid-facets-container"
              >
                {/*  <SubCategoryMenu /> */}

                {isMobileState ? renderMobileFacets : renderFacets}
              </Grid>
              <Grid
                xs={6}
                sm={8}
                md={8}
                lg={9}
                className="grid-category-items-container"
              >
                <Grid
                  container="true"
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item="true" xs={12} style={{ margin: "0 1%" }}>
                    <FacetBreadcrumb data={data} />
                  </Grid>
                  <Grid
                    className="pagination-container"
                    item="true"
                    xs={12}
                    tempStyle={{
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      margin: "0 1%"
                    }}
                  >
                    <Grid
                      container="true"
                      justify="space-around"
                      alignItems="center"
                    >
                      <Grid item="true" {...numberOfItemsGridSizes}>
                        <NumberOfItems data={data} />
                      </Grid>
                      <Grid item="true" xs={6} sm={6} md={3}>
                        <SortBy />
                      </Grid>
                      <Grid item="true" xs={12} sm={12} md={6}>
                        {showDynamicState ? (
                          <PaginationRegular data={data} />
                        ) : (
                          <Pagination
                            setScrollPos={setScrollPos}
                            data={data}
                            pageContext={pageContext}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item="true" xs={12}>
                    <CategoryItems
                      data={data}
                      pageContext={pageContext}
                      supplier={supplier}
                    />
                    {loadingBottom ? <Loading /> : null}
                    <div ref={myRef} />
                    {!loadingBottom &&
                    scroolPageState - currentPageState > 2 &&
                    pagesState.length > scroolPageState ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: "#222e50",
                            borderRadius: "0",
                            boxShadow: "none",
                            fontWeight: "700",
                            textTransform: "uppercase",
                            letterSpacing: "2px",
                            lineHeight: "1.42857143",
                            padding: "6px 15px",
                            fontSize: "16px",
                            color: "white",
                            marginTop: "30px"
                          }}
                          onClick={e => handleNextPage(e, scroolPageState + 1)}
                        >
                          More Items
                        </button>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    } else return null;
  };

  if ((loading && showDynamicState) || initialLoadState) {
    return (
      <div id="bd">
        <div className="main">
          {renderHeader()}
          <Loading className="loadingCategory" />
          <div className="row">
            <div className="category-container">
              {/*    <DistanceFilter loading={loading} /> */}
              <Grid
                container="true"
                justify="space-between"
                alignItems="center"
              >
                <Grid
                  item="true"
                  xs={6}
                  sm={4}
                  md={4}
                  lg={3}
                  className="grid-facets-container"
                ></Grid>
                <Grid
                  xs={6}
                  sm={8}
                  md={8}
                  lg={9}
                  className={`grid-category-items-container${
                    facetsState && facetsState.length == 0 ? " no-facets" : ""
                  }`}
                >
                  <Grid
                    container="true"
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item="true" xs={12} style={{ margin: "0 1%" }}></Grid>
                    <Grid
                      className="pagination-container"
                      item="true"
                      xs={12}
                      tempStyle={{
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        margin: "0 1%"
                      }}
                    >
                      <Grid
                        container="true"
                        justify="space-around"
                        alignItems="center"
                      >
                        <Grid item="true" {...numberOfItemsGridSizes}></Grid>
                        <Grid item="true" xs={6} sm={6} md={3}></Grid>
                        <Grid item="true" xs={12} sm={12} md={6}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item="true" xs={12}>
                      {renderPlaceholderCategoryItems()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      // Regular Layout

      <div id="bd">
        <div
          className={`main${
            data.url === "halloweencontest" ? " halloweenMain" : ""
          } ${data.url === "spring-tonic" ? " springTonic" : ""}
          ${data.url === "spellman" ? " spellman" : ""}
          ${data.url === "50th-anniversary-of-earth-day" ? " earthDay" : ""}
          ${data.url === "virtual-euchre-tournament" ? " euchreMain" : ""}
           ${data.name === "Annual Online Auction" ? " auctionMain" : ""} ${
            data.name === "Block 1" ? " auctionMain auctionBlock1" : ""
          } ${data.name === "Block 2" ? " auctionMain auctionBlock2" : ""} ${
            data.name === "Block 3" ? " auctionMain auctionBlock3" : ""
          } ${data.name === "Block 4" ? " auctionMain auctionBlock4" : ""}`}
        >
          {renderHeader()}
          {data.url === "ice-fishing-raffle" ? <Banner /> : ""}
          {data.url === "presidents-bash" ? <PBTop /> : ""}
          {data.url === "first-lady-fundraiser" ? <FLTop /> : ""}
          {data.url === "hockey-pool" ? <IHTop /> : ""}
          {data.url === "halloweencontest" ? <HCTop /> : ""}
          {data.url === "virtual-euchre-tournament" ? <ETTop /> : ""}
          {data.url === "spellman" ? <SMTop /> : ""}
          {data.url === "fun-run" ? (
            <FRTop data={data} categoryItems={categoryItems} />
          ) : (
            ""
          )}
          {data.url === "sonic" ? <SCTop /> : ""}
          {data.name === "Annual Online Auction" ? (
            <SATop setScrollPos={setScrollPos} />
          ) : (
            ""
          )}
          {data.name === "Spring Tonic" ? <STTop /> : ""}
          {data.name === "50th anniversary of Earth Day" ? <EDTop /> : ""}
          {data.name === "Block 1" ? <SATop setScrollPos={setScrollPos} /> : ""}
          {data.name === "Block 2" ? <SATop setScrollPos={setScrollPos} /> : ""}
          {data.name === "Block 3" ? <SATop setScrollPos={setScrollPos} /> : ""}
          {data.name === "Block 4" ? <SATop setScrollPos={setScrollPos} /> : ""}

          {data.url != "hockey-pool" &&
          data.url != "spring-tonic" &&
          data.url != "fun-run" &&
          data.url != "spellman" &&
          data.url != "sonic" &&
          data.url != "50th-anniversary-of-earth-day"
            ? renderCategoryContent()
            : ""}
        </div>
      </div>
    );
  }
}

export default BDCategory;
