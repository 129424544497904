import React from "react";
import CartValidationErrors from "../AC-ProductPage/Components/CartValidationErrors/CartValidationErrors";
import SimpleModal from "./LiveStreamModal";
import { Link } from "gatsby";

import raffleLogo from "../../assets/img/raffleLogo.jpg";
import bellLogo from "../../assets/img/sponsors/bellLogo.png";
import theSarjeantLogo from "../../assets/img/sponsors/theSarjeantLogo.png";
import northernPlumbingLogo from "../../assets/img/sponsors/northernPlumbingLogo.png";
import jeffWaltersJewellers from "../../assets/img/sponsors/jeffWaltersJewellers.png";
import avettiLogo from "../../assets/img/sponsors/avettiLogo.png";
import sponsorLogo2 from "../../assets/img/sponsors/Rock95-logotagline.png";
import sponsorLogo3 from "../../assets/img/sponsors/KoolfFM-logotagline.png";
import Prize from "../../assets/pdf/Prize-list.pdf";

const Banner = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="raffleNotice row text-center">
        <div>
          <p>
            <strong>
              The Draw has now completed but you can watch it again below.
            </strong>
          </p>
          <p>
            <strong>The winners will be contacted by phone.</strong>
          </p>
          <p>
            <strong>
              <a href={Prize} rel="noopener noreferrer" target="_blank">
                Click here to view the Winners List!
              </a>
            </strong>
          </p>
          <p>
            <strong>Thank you for supporting RVH.</strong>
          </p>
        </div>
      </div>

      <div className="raffle row">
        <div className="raffleLeft">
          {/*<img className="img-responsive" src={koolFmLogo}*/}
          {/*     style={{ width: "150px",display: "inline-block", textAlign: "center"}}*/}
          {/*     alt="Kool FM Logo" />*/}
          <img className="img-responsive" src={raffleLogo} />

          <Link to="/ice-fishing-raffle/ice-fishing-raffle-tickets-ainkoh3zq2">
            Get a Raffle Ticket Today
          </Link>

          {/*<img className="img-responsive" src={theSarjeantLogo}*/}
          {/*     style={{ width: "100px", display: "inline-block", marginTop:"15px", textAlign: "center"}}*/}
          {/*     alt="The Sarjeant Logo" />*/}
        </div>

        <div className="raffleCenter">
          {/*<img src={northernPlumbingLogo} style={{ width: "150px" }} alt="Bell Logo" />*/}

          <p>
            The draw will be held at the South Shore Centre, 205 Lakeshore Dr,
            Barrie, ON L4N 7Y9 (
            <a
              href="https://www.google.ca/maps/dir/44.5939712,-79.4427392/205+Lakeshore+Dr,+Barrie,+ON+L4N+7Y9/@44.4773425,-79.7270392,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x882abccf628d53a3:0xc479248531b516f1!2m2!1d-79.6803362!2d44.3740698"
              target="_blank"
            >
              Driving Directions
            </a>
            ) as part of the weekly{" "}
            <a href="https://www.barrierotary.com/" target="_blank">
              Rotary Club of Barrie
            </a>{" "}
            Lunch Time Meeting at 12:45 pm on JUNE 10th 2021
          </p>

          <p>
            <small>
              (Limited access rules may apply due to COVID-19 considerations)
            </small>
          </p>

          <p>
            All proceeds will go to the{" "}
            <a href="https://foundation.rvh.on.ca/events/upcoming-events/rotary-club-of-barrie-ice-fishing-tournament-raffle">
              Royal Victoria Hospital Capital Campaign
            </a>
            .
          </p>

          <p>Prizes will be drawn in the order shown below.</p>

          <p style={{ textTransform: "uppercase" }}>
            Only 1,000 Tickets Available!
          </p>
          {/*<img src={bellLogo} style={{ width: "100px" }} alt="Bell Logo" />*/}
        </div>

        <div
          className="raffleRight"
          style={{ position: "relative", textAlign: "center" }}
        >
          <SimpleModal />
          {/*<img src={northernPlumbingLogo} style={{ width: "150px", display: "inline-block", marginTop:"15px", textAlign: "center"}} alt="Bell Logo" />*/}
        </div>
      </div>

      {/*<h1 style={{marginBottom: "10px"}}>Raffle</h1>*/}

      <div className="row">
        <div id="sponsors">
          <div>
            <h2
              className="rafflePrizeTitle"
              style={{ textAlign: "center", marginLeft: "5%" }}
            >
              Thank You To Our Sponsors
            </h2>
          </div>
          <div className="raffleSponsor">
            <div className="sponsorImg" style={{ width: "350px" }}>
              <img className="img-responsive" src={bellLogo} />
            </div>
          </div>

          <div className="raffleSponsor">
            <div className="sponsorImg" style={{ width: "150px" }}>
              <img className="img-responsive" src={theSarjeantLogo} />
            </div>
            <div className="sponsorImg" style={{ width: "320px" }}>
              <img className="img-responsive" src={jeffWaltersJewellers} />
            </div>
            <div className="sponsorImg" style={{ width: "250px" }}>
              <img className="img-responsive" src={northernPlumbingLogo} />
            </div>
          </div>
          <div className="raffleSponsor">
            <div className="sponsorImg" style={{ width: "155px" }}>
              <img className="img-responsive" src={sponsorLogo2} />
            </div>
            <div className="sponsorImg" style={{ width: "155px" }}>
              <img className="img-responsive" src={sponsorLogo3} />
            </div>
            <div className="sponsorImg" style={{ width: "155px" }}>
              <img className="img-responsive" src={avettiLogo} />
            </div>
          </div>
        </div>
        <h2 className="rafflePrizeTitle">Raffle Prize List:</h2>
      </div>
    </div>
  );
};

export default Banner;
