import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

const Banner = () => {

  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopup = () => {
    setPopupOpen(current => !current);
  };

  return (
    <div>
      <div id="earthDayGrid">
        
        <div id="earthDayLogo">
          <img className="img-responsive" src="https://ik.imagekit.io/ofb/RT/rtLogoEarthDay_wX1laCi5A.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658944746540" />

          <h1>Earth Day Event</h1>
        </div>

        <div id="earthDayInfo">
          <p>Celebrate the 50th anniversary of Earth Day with Award Winning Author and CBC Personality <strong>Bob McDonald</strong>, Host of Quirks & Quarks. His presentation is titled “<strong>The Future is Now</strong>”.</p>

          <p>Is a global pandemic what it took to show us that saving our planet is possible? In the absence of motorized boats and gondolas, Venice’s waters have returned to a sparkling blue color. Deer have been spotted roaming cities in Italy, and mountain goats recently took over a small seaside town in Wales. Taking advantage of the decreased boat traffic, whales have returned to roaming Vancouver’s harbours. The absence of “regular” human activities has dramatically affected our environment.</p>

          <p>Funds raised not only help "Make Barrie Better" but will help address world issues through Rotary's international projects.</p>

          <p>Tuesday September 20th, 2022 at Georgian College Theatre, 7:30 pm </p>

          <p>Tickets are $25.00.</p>

        </div>

        <div id="earthDayBob">
          <img src="https://ik.imagekit.io/ofb/RT/bob_Xgakf4IJb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1658945423639" />
        </div>

        <div id="earthDayBtn">

          <p><a href="https://tprocob.ticketpro.ca/en/pages/COB_RotaryEarthDay?aff=cob" target="_blank">Get your Ticket Today</a></p>

        </div>

        <div id="earthDaySponsorsBlock">

          <p className="earthDaySponsors">Thank You to Our Sponsors</p>

          <div className="sponsorImages sponsorImagesLevel1">
            <div id="sponsorBusch">
              <img src="https://ik.imagekit.io/ofb/RT/busch_Kxngx9h-G.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659972859982" />
            </div>
          </div>
          <div className="sponsorImages sponsorImagesLevel2">
            <div id="sponsorBaywood">
              <img src="https://ik.imagekit.io/ofb/RT/baywoodSouth_zz8I_Tcr6g.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720965" />
            </div>
            <div id="sponsorSurelock">
              <img src="https://ik.imagekit.io/ofb/RT/surelock_mWmwkdWs3K.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720952" />
            </div>
          </div>

          <div className="sponsorImages sponsorImagesLevel3">
            <div id="sponsorBarriston">
              <img src="https://ik.imagekit.io/ofb/RT/barriston_jROBrOUf8.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720827" />
            </div>
            <div id="sponsorSarjent">
              <img src="https://ik.imagekit.io/ofb/RT/sarjent_C86OCLWI2w.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720836" />
            </div>
            <div id="sponsorPowellJones">
              <img src="https://ik.imagekit.io/ofb/RT/powelljones_jsaGLVOVZr.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720985" />
            </div>
            <div id="sponsorRBC">
              <img src="https://ik.imagekit.io/ofb/RT/rbc_fZ9L8OfMx.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720977" />
            </div>
            <div id="sponsorTelequip">
              <img src="https://ik.imagekit.io/ofb/RT/telequip_nZAtCyYb6.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720840" />
            </div>
            <div id="sponsorDooley">
              <img src="https://ik.imagekit.io/ofb/RT/dooley_RimtfjDaf1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720866" />
            </div>
          </div>
          <div className="sponsorImages sponsorImagesLevel3">
            <div id="sponsorInvesco">
              <img src="https://ik.imagekit.io/ofb/RT/invesco_SSl9TpW22.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720842" />
            </div>
            <div id="sponsorAdvance">
              <img src="https://ik.imagekit.io/ofb/RT/barrieadvance_eevPWBKI1E.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720921" />
            </div>
            <div id="sponsorKool">
              <img src="https://ik.imagekit.io/ofb/RT/kool_tpNNzFLAiX.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720885" />
            </div>
            <div id="sponsorRock95">
              <img src="https://ik.imagekit.io/ofb/RT/rock95_71T5WVkD6.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720846" />
            </div>
            <div id="sponsor360">
              <img src="https://ik.imagekit.io/ofb/RT/barrie360_ppx3TIstuI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970721020" />
            </div>
            <div id="sponsorGreen">
              <img src="https://ik.imagekit.io/ofb/RT/livingGreen_QJjUUAIY-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659970720823" />
            </div>
          </div>
        </div>

        
      </div>

      
    </div>
  );
};

export default Banner;
