import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

const Banner = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopup = () => {
    setPopupOpen(current => !current);
  };

  return (
    <div>
      <div id="springTonicGrid">
        <div id="springTonicLeftImg"></div>
        <div id="springTonicLogo">
          <img
            className="img-responsive"
            src="https://ik.imagekit.io/ofb/RT/springTonicLogo_C9Wl5nwMgoAK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1647451593637"
          />
        </div>

        <div id="springTonicInfo">
          <p>
            <strong>Saturday April 6th - Sunday April 7th, 2024</strong>
          </p>
          <p>
            <strong>Time Slots:</strong>
            <br />
            Festival runs each day between 9am-3pm,
            <br /> tickets valid all day, please purchase your ticket
            <br />
            based on the dining time slot preferred
            <br />
            <strong>Morning:</strong> 9am - 11am
            <br />
            <strong>Afternoon:</strong> 11:30am - 2pm
            <br />
            Pancakes Served until 2pm
          </p>
          <p>
            <strong>Tiffin Conservation Area</strong>
            <br />
            8195 8th Line, Utopia ON
            <a
              href="https://www.google.com/maps/dir//Tiffin+Centre+for+Conservation+8195+8th+Line,+Utopia+ON/@44.30027,-79.8307844,10.75z/data=!4m8!4m7!1m0!1m5!1m1!1s0x882a9428bf55454b:0xa3280ec3317727f6!2m2!1d-79.799081!2d44.3163048"
              target="_blank"
            >
              (click for driving directions)
            </a>
          </p>
          <p>
            <strong>Family Pack (2 Adults + 2 Children):</strong> $65.00 <br />
            <strong>Adults & Teens (13+):</strong> $20.00 <br />
            <strong>Children 3-12:</strong> $15.00 <br />
            <strong>Children Under 3:</strong> FREE <br/>
            <strong>Build your own pine bird box to take home:</strong> $10.00
            cash or online only <br />
            <strong>
              Purchase pre-built cedar bird box to take home :
            </strong>{" "}
            $30.00 cash or online only <br />
            <strong>Tractor Drawn Wagon</strong>
            <br />
            <strong>
              Red Barn dog show (see canines speed through an obstacle course)
            </strong>
            <br />
          </p>
        </div>

        <div id="springTonicBtn">
          <p>
            <Link to="/spring-tonic/spring-tonic-ticket-ainaoxaeg2">
              Get your Ticket Today
            </Link>
          </p>
        </div>

        <div id="springTonicSponsorsBlock">
          {/* <p className="springTonicSponsors">Thank You to Our Sponsors</p>

          <div className="sponsorImages">
            <a target="_blank" href="https://dwtuck.com/">
              <img
                className="img-responsive"
                src="https://ik.imagekit.io/ofb/RT/dwtuck_oBVFBZVkfr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647521246778"
              />
            </a>
            <a target="_blank" href="https://www.telequip.ca/">
              <img
                className="img-responsive"
                src="https://ik.imagekit.io/ofb/RT/telequip_nZAtCyYb6.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659972452959"
              />
            </a>
          </div> */}
        </div>

        <div id="springTonicRightImg"></div>
      </div>

      <div id="tonicvideo">
        <iframe
          width="560"
          height="400"
          src="https://www.youtube.com/embed/leeQ0hlGl-Y?si=KbnFJGm-nDnh62Oq"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <div id="springTonicGridBottom">
        <div>
          <img
            className="img-responsive"
            src="https://ik.imagekit.io/ofb/RT/springTonic4_u-1AiiBr-h.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647545778782"
          />
        </div>
        <div>
          <img
            className="img-responsive"
            src="https://ik.imagekit.io/ofb/RT/springTonic5_Vmly32HtAz.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647545778795"
          />
        </div>
        <div>
          <img
            className="img-responsive"
            src="https://ik.imagekit.io/ofb/RT/springTonic6_yozist3sQZ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1647545778803"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;