import React, { useContext, useLayoutEffect, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Async from "react-code-splitting";
import "../Styles/StoreCategoryHeader.css";

import fbLogo from "../../../assets/img/sellerFB.png";
import twLogo from "../../../assets/img/sellerTW.png";
import igLogo from "../../../assets/img/sellerIG.png";
import ptLogo from "../../../assets/img/sellerPT.png";

import { setGoBackToCategoryFromProductPage } from "../../../redux/actions/categoryActions.js";
import ChatTheSeller from "../../ACG-ProductPage/Components/ChatTheSeller/ChatTheSeller";
import { I18nContext } from "../../../i18n";

const CategoryBreadcrumb = () => (
  <Async load={import("../../AC-Breadcrumb/CategoryBreadcrumb.jsx")} />
);

export default function StoreCategoryHeaderStore({
  data,
  storeSellerData,
  pickupLocData,
  supplierShippingInfo,
  sellerHaveNoProducts
}) {
  console.error(
    "header store DATA",
    pickupLocData,
    supplierShippingInfo,
    storeSellerData
  );

  const dispatch = useDispatch();
  const categoryNameState = data.name;
  const facetFilter = useSelector(
    state => state.facetReducer.filterUrl,
    shallowEqual
  );

  const storesState = useSelector(
    state => state.storeReducer.stores,
    shallowEqual
  );

  const categoryImageState = useSelector(
    state => state.categoryReducer.catImage,
    shallowEqual
  );

  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  const selectedStoreState = useSelector(
    state => state.storeReducer.selectedStore,
    shallowEqual
  );

  const cidN = data.cid;

  const handleAboutTabs = newTabID => {
    var tabs = document.getElementsByClassName("aboutTab");
    var tabBodies = document.getElementsByClassName("tabBody");

    for (var x = 0; x < tabs.length; x++) {
      tabs[x].classList.remove("activeTab");
    }
    for (var x = 0; x < tabBodies.length; x++) {
      tabBodies[x].classList.add("hidden");
    }

    var newTab = document.getElementById(newTabID + "Tab");
    var newTabBody = document.getElementById(newTabID);

    newTab.classList.add("activeTab");
    newTabBody.classList.remove("hidden");
  };

  useEffect(() => {
    dispatch(setGoBackToCategoryFromProductPage(true));
    return () => {
      setGoBackToCategoryFromProductPage(false);
    };
  }, []);

  useLayoutEffect(() => {
    if (storeSellerData) {
      console.error("storeSellerData", storeSellerData);

      if (pickupLocData && pickupLocData.length > 0) {
        var pickUpDiv = document.getElementById("pickUpData");
        pickUpDiv.innerHTML = "";

        for (var x = 0; x < pickupLocData.length; x++) {
          var tempHTML =
            "<p class='pickupDataBox'><strong>" +
            pickupLocData[x].pickup_location_name +
            "</strong><br/>" +
            pickupLocData[x].address_place +
            " - <a target='_blank' href='https://www.google.com/maps/dir/here/" +
            pickupLocData[x].latitude +
            "," +
            pickupLocData[x].longitude +
            "'>Get Directions</a>";

          if (pickupLocData[x].time != null) {
            tempHTML =
              tempHTML +
              "<br/><strong>Hours:</strong> " +
              pickupLocData[x].time;
          }

          if (pickupLocData[x].additional_information != null) {
            tempHTML =
              tempHTML +
              "<br/><strong>Additional Info:</strong> " +
              pickupLocData[x].additional_information;
          }

          tempHTML = tempHTML + "</p>";

          pickUpDiv.innerHTML = pickUpDiv.innerHTML + tempHTML;
        }
      } else {
        var pickupTab = document.getElementById("pickupInfoTab");
        pickupTab.classList.add("hidden");
      }

      if (supplierShippingInfo && supplierShippingInfo.length > 0) {
        var shippingDiv = document.getElementById("shippingData");
        shippingDiv.innerHTML = "";

        var tempHTML = "<p><strong>Ships:</strong> ";

        if (supplierShippingInfo[0].shipping_type == 0) {
          var shippingTab = document.getElementById("shippingInfoTab");
          shippingTab.classList.add("hidden");
        } else if (supplierShippingInfo[0].shipping_type == 1) {
          tempHTML = tempHTML + "Worldwide";
        } else if (supplierShippingInfo[0].shipping_type == 2) {
          tempHTML = tempHTML + "Within Canada";
        } else if (supplierShippingInfo[0].shipping_type == 3) {
          if (storeSellerData.province) {
            tempHTML = tempHTML + "Within " + storeSellerData.province;
          } else {
            tempHTML = tempHTML + "Within Province";
          }
        } else if (supplierShippingInfo[0].shipping_type == 4) {
          tempHTML =
            tempHTML + "Within " + supplierShippingInfo[0].range + "KM";
        }

        tempHTML = tempHTML + "</p>";

        if (
          supplierShippingInfo[0].instructions &&
          supplierShippingInfo[0].instructions != null
        ) {
          tempHTML =
            tempHTML +
            "<p><strong>Additional Info:</strong> " +
            supplierShippingInfo[0].instructions +
            "</p>";
        }

        shippingDiv.innerHTML = tempHTML;
      } else {
        var shippingTab = document.getElementById("shippingInfoTab");
        shippingTab.classList.add("hidden");
      }
    }
  }, []);

  let imageUrl =
    data.image.replace("images", "largeimages") +
    `?tr=ar-1-1,dpr-2,pr-true,f-auto,w-150`;
  console.error("IMAGE KIT", imageUrl);
  if (imageUrl.includes("sls3.avetti.ca")) {
    imageUrl = imageUrl.replace(
      "sls3.avetti.ca",
      "demob2b2cs3.avetti.ca/store"
    );
  }

  var mapsLink;
  if (
    storeSellerData &&
    Object.keys(storeSellerData).includes("location") &&
    storeSellerData.location
  ) {
    mapsLink = "https://www.google.ca/maps/search/" + storeSellerData.location;
  } else {
    mapsLink = "";
  }

  return (
    <React.Fragment>
      <div className="myContainer">
        <div className="storeInfoWrapper">
          <div className="storeLogo">
            <img src={imageUrl}></img>
          </div>
          <div className="storeInfoMain">
            <div className="storeInfo1">
              <h1 className="storeName testClass">{categoryNameState}</h1>
              {storeSellerData &&
              Object.keys(storeSellerData).includes("year") &&
              storeSellerData.year != "" ? (
                <p>
                  <strong>
                    Established{" "}
                    {storeSellerData &&
                      Object.keys(storeSellerData).includes("year") &&
                      storeSellerData.year}{" "}
                    -{" "}
                    {storeSellerData &&
                      Object.keys(storeSellerData).includes("city") &&
                      storeSellerData.city}
                    ,{" "}
                    {storeSellerData &&
                      Object.keys(storeSellerData).includes("province") &&
                      storeSellerData.province}
                  </strong>
                </p>
              ) : null}

              {storeSellerData &&
              Object.keys(storeSellerData).includes("phone_number") &&
              storeSellerData.phone_number != "" ? (
                <p>
                  <strong>Phone:</strong>{" "}
                  {storeSellerData &&
                    Object.keys(storeSellerData).includes("phone_number") &&
                    storeSellerData.phone_number}
                </p>
              ) : null}

              {storeSellerData &&
              Object.keys(storeSellerData).includes("location") &&
              storeSellerData.location != "" ? (
                <p>
                  <strong>Address:</strong>{" "}
                  {storeSellerData &&
                    Object.keys(storeSellerData).includes("location") &&
                    storeSellerData.location}
                  <br />
                  {mapsLink != "" ? (
                    <a className="mapsLink" target="_blank" href={mapsLink}>
                      View on Google Maps
                    </a>
                  ) : null}
                </p>
              ) : null}

              {storeSellerData &&
              Object.keys(storeSellerData).includes("website") &&
              storeSellerData.website != null ? (
                <p>
                  <strong>Website:</strong>{" "}
                  <a
                    style={{ color: "#289dd6" }}
                    href={storeSellerData.website}
                    target="_blank"
                  >
                    {storeSellerData.website}
                  </a>
                </p>
              ) : null}
              <p className="sellerSocialLine">
                {storeSellerData !== undefined &&
                (Object.keys(storeSellerData).includes("facebook") ||
                  Object.keys(storeSellerData).includes("twitter") ||
                  Object.keys(storeSellerData).includes("instagram") ||
                  Object.keys(storeSellerData).includes("pinterest")) &&
                (storeSellerData.facebook ||
                  storeSellerData.twitter ||
                  storeSellerData.instagram ||
                  storeSellerData.pinterest) ? (
                  <span>
                    <strong>Connect With Us:</strong>
                    <br />
                  </span>
                ) : null}

                {storeSellerData && storeSellerData.facebook ? (
                  <a href={storeSellerData.facebook}>
                    <img className="sellerSocialLogo" src={fbLogo} />
                  </a>
                ) : null}
                {storeSellerData && storeSellerData.twitter ? (
                  <a href={storeSellerData.twitter}>
                    <img className="sellerSocialLogo" src={twLogo} />
                  </a>
                ) : null}
                {storeSellerData && storeSellerData.instagram ? (
                  <a href={storeSellerData.instagram}>
                    <img className="sellerSocialLogo" src={igLogo} />
                  </a>
                ) : null}
                {storeSellerData && storeSellerData.pinterest ? (
                  <a href={storeSellerData.pinterest}>
                    <img className="sellerSocialLogo" src={ptLogo} />
                  </a>
                ) : null}
              </p>
            </div>
            {storeSellerData && (
              <div className="storeInfo2 storeHeader">
                <div className="aboutTabs">
                  <h2
                    id="aboutInfoTab"
                    className="storeName aboutTab activeTab"
                    onClick={() => {
                      handleAboutTabs("aboutInfo");
                    }}
                  >
                    About Us
                  </h2>
                  <h2
                    id="pickupInfoTab"
                    className="storeName aboutTab"
                    onClick={() => {
                      handleAboutTabs("pickupInfo");
                    }}
                  >
                    Pickup Info
                  </h2>
                  <h2
                    id="shippingInfoTab"
                    className="storeName aboutTab"
                    onClick={() => {
                      handleAboutTabs("shippingInfo");
                    }}
                  >
                    Shipping Info
                  </h2>
                </div>

                <div id="aboutInfo" className="tabBody">
                  <p>
                    {storeSellerData &&
                      storeSellerData.description &&
                      storeSellerData.description.blocks &&
                      storeSellerData.description.blocks[0] &&
                      storeSellerData.description.blocks[0].text}
                  </p>
                </div>
                <div id="pickupInfo" className="tabBody hidden">
                  <div id="pickUpData"></div>
                </div>
                <div id="shippingInfo" className="tabBody hidden">
                  <div id="shippingData"></div>
                </div>
              </div>
            )}
          </div>
          {sellerHaveNoProducts && (
            <div
              className="seller-have-no-products-wrapper"
              style={{ margin: "50px 0" }}
            >
              <h4>
                Sorry this seller has pickup locations that are more than 200 KM
                away and does not ship to your location or has not listed their
                products yet. Chat with them to learn more about what they
                offer.
              </h4>
              <div className="chat-wrapper" style={{ maxWidth: "400px" }}>
                <ChatTheSeller storeSellerData={storeSellerData} />
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
